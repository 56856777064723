<template>
  <div>
    <v-main>
      <div v-if="isLoading" class="loader-container">
        <v-progess-circular indeterminate color="primary"> </v-progess-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-center py-1 my-10">
            <h3>Update Security Pin</h3>
          </v-col>
        </v-row>
        <v-container>
          <v-text-field
            v-model="oldPin"
            label="Old Pin"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="newPin"
            label="New Pin"
            outlined
          ></v-text-field>
          <v-col cols="12" class="text-center py-1">
            <v-btn
              color="#278EA5"
              dark
              @click="updateSecurityPin"
              :disabled="disableButton"
            >
              Update
            </v-btn>
          </v-col>
        </v-container>
      </div>
    </v-main>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL, failureAlert, successAlert } from "@/constants";
export default {
  name: "UpdateSecurityPin",
  data() {
    return {
      isLoading: false,
      oldPin: "",
      newPin: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
    };
  },
  async created() {
    this.memberID = localStorage.getItem("memberId");
    this.isLoading = true;
    this.fetchUserDetails(this.memberID);
    this.isLoading = false;
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    async updateSecurityPin() {
      try {
        if (this.oldPin === this.newPin) {
          alert("Old pin and new pin should not be same");
          return;
        }
        this.disableButton = true;
        var body = {
          currentPin: this.userDetails.securityPin,
          newPin: this.newPin,
        };
        var res = await axios.post(
          `${BASE_URL}/auth/update_security_pin/${this.memberID}`,
          body
        );
        this.disableButton = false;
        if (res.status === 200 || res.status === 201) {
          
          successAlert("Updated successfully");
          this.oldPin = "";
          this.newPin = "";
          this.isLoading = true;
          await this.fetchUserDetails(this.memberID);
          this.isLoading = false;
          this.disableButton = false;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        failureAlert("Sorry","Failed to update pin");
      }
    },
  },
};
</script>
