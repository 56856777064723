<template>
    <div>
        <div><h3 class="heading text-center m-10">My Store</h3></div>
        <MerchantTable :headers="merchantTableKeys" :rows="productList" />
    </div>
</template>
<script>
import { BASE_URL, failureAlert } from "@/constants";
import MerchantTable from "./MerchantTable.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
    name: "MerchantStore",
    components: { MerchantTable },
    data() {
        return {
            merchantTableKeys: ["sNo", "Name","price","quantity","imageUrl"],
            fetchingProducts: false,
            productList: [],
            memberId: "",
        }
    },
    async created() {
        this.memberId = localStorage.getItem("memberId");
        if (!this.memberId) {
            this.$router.push("/login");
            return;
        }
        this.fetchingProducts = true;
        await this.fetchAllProducts();
        this.fetchingProducts = false;
    },
    computed: {
        ...mapState(["userDetails", "userId", "userName"]),
    },
    methods: {
        ...mapActions(["fetchUserDetails"]),
        async fetchAllProducts() {
            // Call API to fetch all products
            try {
                const response = await axios.get(`${BASE_URL}/merchant/fetchProducts/${this.memberId}`);
                this.productList = response.data;
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while fetching products");
            }
        },
    }
}
</script>