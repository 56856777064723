// export const BASE_URL = "http://localhost:3000";
export const BASE_URL = "https://vcanshoppe.com";
// export const TEST_URL = "https://vcanshoppe.com/";
import Swal from "sweetalert2";

export const successAlert = (msg) => {
  return Swal.fire({
    icon: "success",
    title: msg,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const failureAlert = (title, text) => {
  return Swal.fire({
    icon: "error",
    title: title,
    text: text,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const constants = {
  MERCHANT: "Merchant",
};
