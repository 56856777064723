<template>
  <div>
    <LevelTeam />
  </div>
</template>

<script>
import LevelTeam from "./LevelTeam.vue";

export default {
  name: "TabsLevel",
  components: {
    LevelTeam,
  },
};
</script>
