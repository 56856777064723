<template>
  <div>
    <v-container>
      <v-card elevation="5" class="mx-auto responsive-card">
        <v-card-text>
          <v-row justify="center" class="my-0 py-0">
            <v-col cols="12" class="text-center py-1 mb-5">
              <h2>Delete Account</h2>
            </v-col>
            <v-col cols="12" class="text-center py-1">
              <v-text-field
                v-model="memberId"
                label="Enter MemberID"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="securityPin"
                label="Enter Security Pin"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="text-center">
              <v-btn
                color="#278EA5"
                dark
                @click="deleteAccount"
                :disabled="disableButton"
              >
                <p v-if="disableButton">Deleting... ></p>
                <p v-else>Delete</p>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL,successAlert,failureAlert } from "@/constants";
export default {
  name: "DeleteAccount",
  data() {
    return {
      memberId: "",
      securityPin: "",
      disableButton: false,
    };
  },
  methods: {
    async deleteAccount() {
      this.disableButton = true;
      try {
        var res = await axios.delete(
          `${BASE_URL}/user/delete/${this.memberId}/${this.securityPin}`
        );
        if (res.status === 200 || res.status === 201) {
          successAlert("Deleted successfully");
          this.memberId = "";
          this.securityPin = "";
          this.$router.replace({ name: "login" });
        }
      } catch (e) {
        failureAlert("Sorry",e.response.data.message);
      }
      this.disableButton = false;
    },
  },
};
</script>
