<template>
  <div>
    <v-main>
      <v-container>
        <div v-if="isLoading" class="loader-container">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else class="mx-auto responsive-card align-center">
          <div v-if="showDialog">
            <!-- The v-dialog component -->
            <v-dialog v-model="showDialog" max-width="600px" class="align-center">
              <v-card class="text-center" max-width="400px p-10">
                <v-card-title class="headline">Withdrawal Summary</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">You will get:</v-col>
                    <v-col cols="6"><strong>₹ {{ amount - 0.1 * amount }}</strong></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">Admin charges :</v-col>
                    <v-col cols="6"><strong>₹ {{ 0.1 * amount }}</strong></v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-btn color="green darken-1" text @click="showDialog = false">Cancel</v-btn>
                    <v-btn color="green darken-1" text @click="requestWithdrawl">Withdraw Money</v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Other components... -->
          </div>
          <v-container fluid>
            <v-card elevation="5" class="mx-auto" max-width="600">
              <v-card-text>
                <v-row justify="center" class="my-0 py-0">
                  <v-col cols="12" class="text-center py-1">
                    <h2 class="text-decoration-underline">
                      New Withdrawl Request
                    </h2>
                  </v-col>
                  <v-col cols="12" class="text-center py-3">
                    <h4>
                      Available Wallet: ₹ {{ userDetails["availableWallet"] }}
                    </h4>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field v-model="amount" label="Amount in INR(₹)" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field v-model="password" label="Transaction Password" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn color="#278EA5" dark @click="handleClick" :disabled="disableButton">
                      Withdrawl Money
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
// import axios from 'axios';
import { mapState, mapActions } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL, failureAlert, successAlert } from "@/constants";
export default {
  name: "NewWithdrawlPage",

  data() {
    return {
      disableButton: false,
      isLoading: false,
      memberID: localStorage.getItem("memberId"),
      amount: "",
      password: "",
      showDialog: false,
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  async created() {
    this.memberID = localStorage.getItem("memberId");
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchUserDetails(this.memberID);
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),

    async requestWithdrawl() {
      try {
        this.showDialog = false;
        this.disableButton = true;
        const res = await axios.get(
          `${BASE_URL}/withdrawl/new_request/${this.memberID}/${this.amount}/${this.password}`
        ); // Replace with your actual route

        if (res.status === 200 || res.status === 201) {
          successAlert("Money will be transfered in sometime");
          this.amount = "";
          this.password = "";
          await this.fetchUserDetails(this.memberID);
        } else {
          console.log("error");
          console.log(res.data);
          throw new Error(res.data.message);
        }
      } catch (e) {
        console.log("catch error");
        console.log(e);
        failureAlert("Sorry", e.response.data.message);
      }

      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.memberID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.memberID}`
          );
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },

    handleClick() {
      if (this.password !== this.userDetails["securityPin"]) {
        alert("Incorrect Transaction Password");
        return;
      }
      if (
        parseInt(this.amount) > parseInt(this.userDetails["availableWallet"])
      ) {
        alert("Insufficient fund");
        return;
      }
      if (parseInt(this.amount) < 5) {
        alert("Minimum withdrawl amount is 5");
        return;
      }
      if (parseInt(this.amount) % 5 != 0) {
        alert("Please enter amount in multiples of 5");
        return;
      }
      if (parseInt(this.amount) <= 0) {
        alert("Amount should be greater than 0");
        return;
      }
      this.showDialog = true;
    },
  },
};
</script>
<style scoped>
.top-up-card {
  margin-top: 12px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
