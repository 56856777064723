<template>
    <div>
        <div><h3 class="heading text-center m-10">My Orders</h3></div>
        <div v-if="isLoading" class="loader-container">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <MerchantTable v-else :headers="merchantTableKeys" :rows="orders" />
    </div>
</template>
<script>
import { BASE_URL, failureAlert } from "@/constants";
import MerchantTable from "../merchant/MerchantTable.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
    name: "CustomerOrderHistory",
    components: { MerchantTable },
    data() {
        return {
            merchantTableKeys: ["SNo", "Name","Quantity","Total Amount","Image","Status"],
            isLoading: false,
            orders: [],
            memberId: "",
        }
    },
    async created() {
        this.memberId = localStorage.getItem("memberId");
        if (!this.memberId) {
            this.$router.push("/login");
            return;
        }
        this.isLoading = true;
        await this.fetchOrders();
        this.isLoading = false;
    },
    computed: {
        ...mapState(["userDetails", "userId", "userName"]),
    },
    methods: {
        ...mapActions(["fetchUserDetails"]),
        async fetchOrders() {
            // Call API to fetch all products
            try {
                const response = await axios.get(`${BASE_URL}/user/orders/${this.memberId}`);
                this.orders = response.data;
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", error.response.data.message);
            }
        },
    }
}
</script>