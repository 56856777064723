<template>
  <div class="container pb-16 mx-auto">
    <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6 underline">New Arrivals</h2>

    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-6 ">


      <!-- product -->
      <div v-for="(product, index) of products" :key="index">
        <ProductBox @click="currentProduct(product.id, product.category)" :imgPath="product.img" :name=product.name
          :price="product.price" :stock="product.stock" />
      </div>
      <!-- product end -->


    </div>
  </div>
</template>
<script>
import ProductBox from './ProductItem.vue'

export default {
  name: 'ProductItem',
  components: {
    ProductBox
  },
  data() {
    return {
      products: [
        { "id": 1, "name": "Bottle", "price": 1000.00, "quantity": 4, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-1.jpg", "description": "This is a marvelouse Italian Sofa Good for block house", "category": "home", "brand": "Supreme", "stock": 50 },
        { "id": 2, "name": "Bluetooth", "price": 1000.00, "quantity": 1, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-2.jpg", "description": "Long last Men perfumes", "category": "perfume", "brand": "CR7", "stock": 90 },
        { "id": 3, "name": "Modern Watch", "price": 1000.00, "quantity": 5, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-3.jpg", "description": "Modern Sports and workout rubber ", "category": "shoes", "brand": "Nike", "stock": 130 },
        { "id": 4, "name": "Hybrid Umbrella", "price": 1000.00, "quantity": 1, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-4.jpg", "description": "White and black rubber for fussion", "category": "shoes", "brand": "Addidas", "stock": 96 },
        { "id": 5, "name": "Hybrid Umbrella", "price": 1000.00, "quantity": 6, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-4.jpg", "description": "lorem ipsum dolor sit amet, consectetur adip", "category": "perfume", "brand": "CR7", "stock": 14 },
        { "id": 6, "name": "Modern Watch", "price": 1000.00, "quantity": 6, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-3.jpg", "description": "lorem ipsum dolor sit amet, consectetur adip", "category": "perfume", "brand": "CR7", "stock": 14 },
        { "id": 7, "name": "Bluetooth", "price": 1000.00, "quantity": 6, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-2.jpg", "description": "lorem ipsum dolor sit amet, consectetur adip", "category": "perfume", "brand": "CR7", "stock": 14 },
        { "id": 8, "name": "Bottle", "price": 1000.00, "quantity": 6, "img": "https://vcanshoppe.s3.ap-south-1.amazonaws.com/image-1.jpg", "description": "lorem ipsum dolor sit amet, consectetur adip", "category": "perfume", "brand": "CR7", "stock": 14 },
      ]
    }
  },
}
</script>
  