<template>
  <div>
    <v-main>
      <div v-if="isLoading" class="loader-container">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-center py-1 my-10">
            <h3>Address Details</h3>
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="address"
                label="Fill complete Address"
                rows="10"
                cols="30"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="emailRules"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center py-1">
              <v-btn
                color="#278EA5"
                dark
                @click="postAddress"
                :disabled="disableButton || !isEmailValid"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </div>
</template>

<script>
import { BASE_URL, failureAlert, successAlert } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "AddressDetails",
  data() {
    return {
      isLoading: false,
      address: "",
      email: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    };
  },
  computed: {
    isEmailValid() {
      return /.+@.+\..+/.test(this.email);
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchAddress();
    this.isLoading = false;
  },
  methods: {
    async fetchAddress() {
      try {
        const res = await axios.get(
          `${BASE_URL}/fund/user_details/${this.memberID}`
        );
        if (res.status === 200) {
          this.address = res.data.address;
          this.email = res.data.email;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log("Error while finding address details");
        alert("Error occurred while finding address details");
      }
    },
    async postAddress() {
      try {
        this.disableButton = true;
        const body = {
          address: this.address,
          email: this.email,
        };
        const res = await axios.post(
          `${BASE_URL}/fund/add_address/${this.memberID}`,
          body
        );
        this.disableButton = false;
        if (res.status === 200 || res.status === 201) {
          successAlert("Updated successfully");
          await this.fetchAddress(this.memberID);
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        failureAlert("Sorry","Failed to update address");
      }
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
