<template>
  <div>
    <v-main>
      <v-container>
        <div v-if="isLoading" class="loader-container">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-container fluid>
            <v-card elevation="5" class="mx-auto" max-width="600">
              <v-card-text>
                <v-row justify="center" class="my-0 py-0">
                  <v-col cols="12" class="text-center py-1">
                    <h2 class="text-decoration-underline">Transfer Fund</h2>
                  </v-col>
                  <v-col cols="12" class="text-center py-3">
                    <h4>
                      Available Fund: ₹ {{ userDetails["availableFunds"] }}
                    </h4>
                  </v-col>

                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="toID"
                      label="Enter MemberID"
                      outlined
                      @input="fetchParentName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="parentName"
                      label="Member Name"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="amount"
                      label="Amount"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="password"
                      label="Transaction Password"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      color="#278EA5"
                      dark
                      @click="transferFund"
                      :disabled="disableButton"
                    >
                      Transfer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
// import axios from 'axios';
import { mapState, mapActions } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL, failureAlert, successAlert } from "@/constants";
export default {
  name: "NewFundTransfer",

  data() {
    return {
      disableButton: false,
      isLoading: false,
      toID: "",
      amount: "",
      password: "",
      parentName: "",
      fromID: localStorage.getItem("memberId"),
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  async created() {
    this.fromID = localStorage.getItem("memberId");
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchUserDetails();
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),

    async transferFund() {
      try {
        if (this.fromID === this.toID) {
          alert("Transfer cannot be done to same user");
          return;
        }
        if (this.password !== this.userDetails["securityPin"]) {
          alert("Incorrect Transaction Password");
          return;
        }

        if (
          parseInt(this.amount) > parseInt(this.userDetails["availableFunds"])
        ) {
          alert("Insufficient fund");
          return;
        }
        if (parseInt(this.amount) <= 0) {
          alert("Amount should be greater than 0");
          return;
        }
        this.disableButton = true;
        const body = {
          fromId: this.fromID, // Replace with the actual user ID
          toId: this.toID,
          amount: this.amount,
          pin: this.password,
        };
        const res = await axios.post(
          `${BASE_URL}/fund/transfer/fund_to_fund`,
          body
        ); // Replace with your actual route

        if (res.status === 200 || res.status === 201) {
          this.toID = "";
          this.amount = "";
          this.password = "";
          this.parentName = "";
          successAlert("Fund Transfered Successfully");
        } else {
          failureAlert("Fund Transfer Failed", res.data.message);
        }
        await this.fetchUserDetails();
      } catch (e) {
        console.error(e);
        failureAlert("Fund Transfer Failed", e.response.data.message);
      }

      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.toID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.toID}`
          );
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.top-up-card {
  margin-top: 12px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
