<template>
  <div>
    <v-container fluid>
      <v-card elevation="5" class="mx-auto responsive-card" max-width="">
        <v-card-text>
          <v-row justify="center" class="my-0 py-0">
            <v-col cols="12" class="text-center py-1 mb-5">
              <h2>Tell Us About Your Problem</h2>
            </v-col>
            <v-col cols="12" class="text-center py-1">
              <v-text-field
                v-model="subject"
                label="Enter Subject ex:Withdrawal Problem, TopUp Problem"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center py-1">
              <v-textarea
                label="Describe Problem"
                v-model="description"
                single-line
              >
              </v-textarea>
            </v-col>

            <v-col cols="12" class="text-center">
              <v-btn
                color="#278EA5"
                dark
                @click="submitRequest"
                :disabled="disableButton"
              >
                <p v-if="disableButton">Submitting... ></p>
                <p v-else>Submit</p>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
import Swal from "sweetalert2";
export default {
  name: "NewSupportRequest",

  data() {
    return {
      subject: "",
      description: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
    };
  },
  methods: {
    async submitRequest() {
      try {
        this.disableButton = true;
        var body = {
          memberId: this.memberID,
          description: this.description,
          subject: this.subject,
        };
        var res = await axios.post(
          `${BASE_URL}/fund/support/new_request`,
          body
        );
        if (res.status === 200 || res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "We Will solve issue soon",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          this.subject = "";
          this.description = "";
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }

      this.disableButton = false;
    },
  },
};
</script>
