<template>
  <div>
    <div v-if="loadingIncome" class="loader-container">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-col cols="12" class="text-center py-1">
        <h3 class="m-2 my-5 heading">Income Table</h3>
      </v-col>
      <v-table class="my-table">
        <thead>
          <tr>
            <th
              class="my-table-header"
              v-for="key in IncomeTableKeys"
              :key="key"
            >
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, rowIndex) in IncomeTableValues" :key="rowIndex">
            <td
              class="my-table-cell"
              v-for="(item, index) in value"
              :key="index"
            >
              <div v-if="index === 'view'">
                <a
                  href=""
                  class="view-link"
                  @click.prevent="fetchLevelUsers(rowIndex)"
                  >View</a
                >
              </div>
              <div v-else>
                {{ item }}
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>

    <v-col cols="12" class="text-center py-1">
      <h3 class="m-2 my-5 heading">Level View: {{ selectedLevel+1  }}</h3>
    </v-col>
    <div v-if="loadingIncome || loadingLevels">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-table class="my-table">
        <thead>
          <tr>
            <th
              class="my-table-header"
              v-for="key in LevelTableKeys"
              :key="key"
            >
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, rowIndex) in LevelTableValues" :key="rowIndex">
            <td
              class="my-table-cell"
              v-for="(item, index) in value"
              :key="index"
            >
              {{ item }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "LevelTeam",
  data() {
    return {
      loadingIncome: false,
      loadingLevels: false,
      memberID: "",
      IncomeTableKeys: ["Level", "Members", "Income", "View"],
      IncomeTableValues: [],
      LevelTableKeys: ["SNo", "Name", "MemberID", "SponsorID", "Sponsor Name"],
      LevelTableValues: [],
      levelTableFetched: false,
      cache: {
        incomeData: null,
        levelUsers: new Map(),
      },
      selectedLevel: 1,
    };
  },
  async created() {
    this.memberID = localStorage.getItem("memberId");
    if (!this.memberID) {
      this.$router.push("/login");
      return;
    }
    this.loadingIncome = true;
    await this.initializeData();
    this.loadingIncome = false;
  },
  methods: {
    async initializeData() {
      await Promise.all([this.fetchIncomeData(), this.fetchLevelUsers(0)]);
    },
    async fetchIncomeData() {
      if (this.cache.incomeData) {
        this.IncomeTableValues = this.cache.incomeData;
        return;
      }

      this.loadingIncome = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/level_incomes/${this.memberID}`
        );
        this.IncomeTableValues = response.data;
        this.cache.incomeData = response.data;
        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingIncome = false;
      }
    },

    async fetchLevelUsers(level) {
      this.selectedLevel = level;
      if (this.cache.levelUsers.has(level)) {
        this.LevelTableValues = this.cache.levelUsers.get(level);
        this.levelTableFetched = true;
        return;
      }

      console.log(level);
      this.loadingLevels = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/find_single_level_users/${
            this.memberID
          }/${level + 1}`
        );
       
        this.LevelTableValues = response.data;
        this.cache.levelUsers.set(level, response.data);
        this.levelTableFetched = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingLevels = false;
      }
    },
  },
};
</script>
<style scoped>
.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table-header {
  background-color: #278ea5;
  border: 1px solid #dee2e6;
  color: white;
  font-weight: bold;
  padding: 0.75rem;
  text-align: center;
}

.my-table-cell {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: left;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.heading {
  color: #278ea5;
  text-decoration: underline;
}
.view-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
