<template>
  <v-container class="mx-auto bg-antiquewhite">
    <v-row class="grid-cols-3 items-center justify-items-center mx-auto">
      <!-- Left Column -->
      <v-col cols="12" md="6" lg="6">
        <div>
          <h4 class="text-2xl md:text-3xl font-bold mb-4">THE PRODUCT OF THE FUTURE</h4>
          <p class="text-xl">V can Shoppe Products are physical and digital items created to fulfill specific technological needs and provide innovative solutions.  products often incorporate advanced features and cutting-edge technologies to meet the demands of an ever-evolving digital landscape.</p>
        </div>
      </v-col>
     
      <!-- Right Column -->
      <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-items-center">
        <v-img
          alt="image"
          src="https://vcanshoppe.s3.ap-south-1.amazonaws.com/main_image.jpg"
          class="responsive-img mb-4"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashboardComponent",
  methods: {
    buyNow() {
      // Handle Buy Now button click
    }
  }
};
</script>

<style scoped>
.my-container {
  background-color: antiquewhite;
  max-width: 100%;
  max-height: fit-content;
}



@media (min-width: 768px) {
  .text-h1 {
    font-size: 4rem;
  }
}

@media (min-width: 1024px) {
  .text-h1 {
    font-size: 5rem;
  }
}

.text-paragraph {
  color: black;
  font-size: 1rem; /* Adjust size as needed */
}

@media (min-width: 768px) {
  .text-paragraph {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .text-paragraph {
    font-size: 1.5rem;
  }
}

.button {
  font-weight: bold;
  font-size: 1rem;
  color: black;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .button {
    font-size: 1.5rem;
  }
}

.button:hover {
  background-color: orange; /* Adjust hover color as needed */
}

.responsive-img {
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
}

@media (max-width: 768px) {
  .responsive-img {
    max-width: 300px;
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .responsive-img {
    max-width: 250px;
    max-height: 250px;
  }
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
