<template>
  <div>
    <div class="profile-header">
      <v-img aspect-ratio="3" src="../../assets/profile.png" alt="Profile Picture" />
      <h2 class="username">{{ userName }}</h2>
    </div>

    <div class="profile-info">
      <div class="profile-option">
        <v-icon class="icon">mdi-share</v-icon>
        <p class="title">Referal Id : {{ memberID }}</p>

      </div>

      <div>
        <div v-for="(title, index) in titles" :key="index" @click="handleClick(index)">
          <div class="profile-option" on>
            <v-icon class="icon">{{ icons[index] }}</v-icon>
            <p class="title">{{ title }}</p>
            <v-icon class="arrow">mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ProfilePage",
  data() {
    return {
      isLoading: false,
      memberID: localStorage.getItem("memberId"),
      icons: ["mdi-account", "mdi-account","mdi-home-map-marker", "mdi-lock", "mdi-lock", "mdi-logout"],
      titles: [
        "Bank Details",
        "Sponsor Details",
        "Address",
        "Update Security Pin",
        "Update Password",
        "Logout",
      ],
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetchUserDetails(this.memberID);
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      alert("Copied succesfully");
    },
    handleClick(index) {
      if (index === 5) {
        // Logout logic here
        localStorage.clear();
        this.$router.push("/login");
        return;
      } else if (index === 1) {
        this.$router.push("/profile/sponsor_details");
      } else if (index === 4) {
        this.$router.push("/profile/update_password");
      } else if (index === 3) {
        // Delete account logic here
        this.$router.push("/profile/update_security_pin");
      } else if (index === 2) {
        // Delete account logic here
        this.$router.push("/profile/address");
      } else if (index === 0) {
        // Navigate to other pages logic here
        this.$router.push("/profile/bank_details");
      }
    },
  },
};
</script>

<style scoped>
.username {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-header img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.profile-info {
  padding: 15px;
}

.profile-option {
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* Add this line */
  transition: 0.3s;
  /* Add this line for a smooth hover effect */
}

.profile-option:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  /* Add this line for a hover effect */
}

.profile-option .icon {
  flex-grow: 0;
  margin-right: 15px;
  color: #278ea5;
}

.profile-option .title {
  flex-grow: 1;
  text-align: left;
}

.profile-option .arrow {
  flex-grow: 0;
}

.profile-actions div {
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
}
</style>
