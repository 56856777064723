<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row justify="center" class="login-container">
        <!-- Register Form -->
        <v-col cols="12" md="6" key="register-form">
          <v-card class="elevation-12 login-card">
            <v-toolbar dark flat>
              <v-toolbar-title>New Registration</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="registerForm" v-model="valid" lazy-validation>
                <v-text-field
                  label="Name"
                  v-model="name"
                  :rules="[(v) => (!!v && v.length > 2) || 'Name is required']"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Phone Number"
                  v-model="phone"
                  :rules="[
                    (v) =>
                      (!!v && v.length >= 10 && /^\d+$/.test(v)) ||
                      'Phone number must be greater than 9 digits and contain only numbers',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Password"
                  v-model="password"
                  :rules="[
                    (v) =>
                      (v && v.length >= 6) ||
                      'Password must be at least 6 characters',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Email"
                  v-model="email"
                  :type="'email'"
                  :rules="[
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Referral ID"
                  v-model="referalID"
                  :type="'text'"
                  :rules="textRules"
                  @input="fetchParentName"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Referral Name"
                  v-model="parentName"
                  :type="'text'"
                  :rules="textRules"
                  outlined
                  dense
                  required
                ></v-text-field>
                <!-- <v-radio-group
                  v-model="direction"
                  row
                  class="radio-group"
                  color="primary"
                >
                  <v-row class="pl-8">Select Direction</v-row>
                  <v-row class="pl-5">
                    <v-radio label="Left" value="Left"></v-radio>
                    <v-radio label="Right" value="Right"></v-radio>
                  </v-row>
                </v-radio-group> -->
                <v-text-field
                  label="Security PIN"
                  v-model="securityPin"
                  :type="'password'"
                  :rules="textRules"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-btn
                  color="primary"
                  dark
                  @click="register"
                  :disabled="disableButton || !valid"
                  class="v-btn-custom"
                  style="margin: 0 auto; display: block"
                >
                  <p v-if="disableButton">Registering...</p>
                  <p v-else>Register</p>
                </v-btn>
              </v-form>
              <v-row class="align-center justify-center m-5">
                <v-btn
                  text
                  @click="$router.push({ name: 'login' })"
                  class="v-btn-text"
                >
                  Back to Login
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped>
.login-container {
  animation: fadeIn 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.move-right {
  margin-left: 20px;
}
.move-left {
  margin-left: 20px;
}
.space-left {
  margin-left: 20px;
}
.align-center {
  display: flex;
  justify-content: center;
}
.toolbar-center {
  display: flex;
  justify-content: center;
}

.v-toolbar {
  background-color: #278ea5 !important;
}

.login-card {
  border-radius: 5px;
  overflow: hidden;
}

.v-btn {
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.v-btn:hover {
  background-color: #1b5f6b !important;
  transform: translateY(-2px);
}

.v-btn-text {
  color: #278ea5 !important;
}

.v-btn-custom {
  background-image: linear-gradient(to right, #43cea2, #185a9d);
  color: white;
}

.login-container h4 {
  color: #185a9d;
}

.login-container p {
  color: #333;
}

.v-text-field,
.v-textarea {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.radio-group {
  justify-content: space-between;
}

.v-text-field::placeholder,
.v-textarea::placeholder {
  color: #bbb;
}

.v-card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.v-toolbar-title {
  font-weight: bold;
  font-size: 1.2em;
}

.v-radio-group {
  padding: 10px 0;
}
</style>

<script>
import { BASE_URL, failureAlert,successAlert } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "RegisterView",
  created() {
    this.referalID = this.$route.query.referalID || "";
    // this.direction = this.$route.query.direction || "Left";
    if (this.referalID.length !== 0) {
      console.log("referalId exists in query string");
      this.fetchParentName();
    }
  },
  data: () => ({
    isLoading: false,
    disableButton: false,
    valid: true,
    phoneNo: "",
    password: "",
    name: "",
    phone: "",
    referalID: "",
    parentName: "",
    // direction: "Left",
    email: "",
    securityPin: "",
  }),
  methods: {
    async register() {
      try {
        this.disableButton = true;
        var body = {
          name: this.name,
          password: this.password.trim(),
          phoneNo: this.phone.trim(),
          email: this.email.trim(),
          referralCode: this.referalID.trim(),
          // position: this.direction,
          securityPin: this.securityPin.trim(),
          isMerchant:false
        };
        const response = await axios.post(
          `${BASE_URL}/auth/register`,
          body
        );
        if (response.status === 200 || response.status === 201) {
          successAlert("Registered successfully");
          this.isLoading = false;
          localStorage.setItem("memberId", response.data.memberId);
          localStorage.setItem("isLoggedIn", true);
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        failureAlert("Registration Failed",error.response.data.message);
      }
      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.referalID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.referalID}`
          );
          console.log(response.data);
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.rounded-img {
  border-radius: 20%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
