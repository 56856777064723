<template>
  <div class="mx-auto">

  
  <v-footer dark >
    <v-card flat tile class="black white--text py-12 px-5" width="100%">
      <v-row>
        <v-col cols="6" sm="4">
          <v-card-text class="white--text pt-0">
            <h3 class="font-bold" >VCANSHOPPE</h3>
          </v-card-text>
          <v-card-text class="grey--text pt-0">
            Shop All
          </v-card-text>
          <v-card-text class="grey--text pt-0">
            Shipping & Returns
          </v-card-text>
          <v-card-text class="grey--text pt-0">
            Store & Policy
          </v-card-text>
          <v-card-text class="grey--text pt-0">
            FAQ
          </v-card-text>
          <v-card-text class="grey--text"> &#169; 2024 -2026 </v-card-text>
        </v-col>
        <v-col cols="6" sm="4">
          <v-card-text class="white--text pt-0">
            <h3 class="font-bold">Address</h3>
          </v-card-text>
          <v-card-text class="grey--text"> HNO : XXX,Flat No: XXX</v-card-text>
          <v-card-text class="grey--text"> Street Name:XXXXX</v-card-text>
          <v-card-text class="grey--text"> State:XXXX,PIN CODE:XXXXXX</v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-text class="white--text pt-0">
            <h3 class="font-bold">Working Hours</h3>
          </v-card-text>
          <v-card-text class="grey--text"> Mon - Fri: 7am - 10pm </v-card-text>
          <v-card-text class="grey--text mt-n4">Saturday: 8am - 10pm</v-card-text>
          <v-card-text class="grey--text mt-n4"> Sunday: 8am - 11pm </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</div>
</template>

<script>
export default {
  name:"FooterComponent",
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style>
</style>



<!-- <template>
  <div class="bg-gray-800 py-4">
      <div class="container flex items-center justify-between">
          <p class="text-white">&copy; MPB SHOP - All Right Reserved</p>
          <div>
              <img src="../../assets/images/methods.png" alt="methods" class="h-5">
          </div>
      </div>
  </div>
</template> -->

<!-- <script>
export default {
  name:"FooterComponent",
}

</script> -->