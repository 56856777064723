<template>
  <div>
    <v-main>
      <div v-if="isLoading" class="loader-container">
        <v-progess-circular indeterminate color="primary"> </v-progess-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-center py-1 my-10">
            <h3>Bank Details</h3>
          </v-col>
        </v-row>
        <v-container>
          <v-text-field v-model="upiID" label="UPI ID" outlined></v-text-field>
          <v-text-field
            v-model="accountHolderName"
            label="Account Holder Name"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="bankName"
            label="Bank Name"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="ifscCode"
            label="IFSC Code"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="accountNumber"
            label="Bank Account Number"
            outlined
          ></v-text-field>
          <v-col cols="12" class="text-center py-1">
            <v-btn
              color="#278EA5"
              dark
              @click="postBankDetails"
              :disabled="disableButton"
            >
              Save
            </v-btn>
          </v-col>
        </v-container>
      </div>
    </v-main>
  </div>
</template>
<script>
import { BASE_URL, failureAlert, successAlert } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "BankDetails",
  data() {
    return {
      isLoading: false,
      upiID: "",
      accountHolderName: "",
      bankName: "",
      ifscCode: "",
      accountNumber: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
    };
  },
  async created() {
    this.isLoading = true;
    // Fetch user details using Vuex action
    this.fetchBankDetails();
    this.isLoading = false;
  },

  methods: {
    async fetchBankDetails() {
      try {
        var res = await axios.get(
          `${BASE_URL}/admin/bank_details/${this.memberID}`
        );
        this.isLoading = false;
        if (res.status === 200) {
          this.upiID = res.data.upiId;
          this.accountHolderName = res.data.accountHolderName;
          this.bankName = res.data.bankName;
          this.ifscCode = res.data.ifscCode;
          this.accountNumber = res.data.accountNumber;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log("Error while finding bank details");
        alert("Error Occured while finding bank details");
      }
    },
    async postBankDetails() {
      try {
        this.disableButton = true;
        var body = {
          accountHolderName: this.accountHolderName,
          bankName: this.bankName,
          accountNumber: this.accountNumber,
          ifscCode: this.ifscCode,
          upiId: this.upiID,
        };
        var res = await axios.post(
          `${BASE_URL}/admin/add_bank_details/${this.memberID}`,
          body
        );
        this.disableButton = false;
        if (res.status === 200 || res.status === 201) {
          successAlert("Updated successfully");
          await this.fetchBankDetails(this.memberID);
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        failureAlert("Sorry","Failed to update bank details");
      }
    },
  },
};
</script>
