<template>
    <v-row class="product-list">
      <v-col v-for="product in products" :key="product._id" cols="12" sm="6" md="4" lg="3">
        <div class="product-card">
          <img :src="product.imageUrl" alt="Product Image" class="product-image">
          <h3 class="product-name">{{ product.name }}</h3>
          <p>Price: ₹ {{ product.price }}</p>
          <p>Stock: <b>{{ product.quantity }}</b></p>
          <div class="quantity-input">
            <label for="quantity">Quantity:</label>
            <input type="number" v-model="product.userQuantity" min="1" class="quantity-field" @input="setDefaultQuantity(product)">
          </div>
        </div>
        <v-col cols="12" class="text-center mt-4">
          <v-btn color="primary" @click="openDialog(product)">Place Order</v-btn>
        </v-col>
      </v-col>
  
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline text-center">Order Summary</v-card-title>
          <v-card-text class="text-center">
            <p>Product: <b>{{ selectedProduct?.name }}</b></p>
            <p>Quantity of products: <b>{{ selectedProduct?.userQuantity ?? 1 }}</b></p>
            <p>Total price: ₹ <b>{{ selectedProduct?.price * (selectedProduct?.userQuantity ?? 1) }}</b></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="dialog = false">Cancel</v-btn>
            <v-btn color="green" @click="submitOrder">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  
  
<script>
import { mapState, mapActions } from "vuex";
import { BASE_URL, failureAlert, successAlert } from "../../constants";
import axios from "axios";

export default {
  name: 'ProductList',
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selectedProduct: null
    };
  },
  created() {
    this.initializeProductQuantities();
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    initializeProductQuantities() {
      this.products.forEach(product => {
        this.$set(product, 'userQuantity', 1);
      });
    },
    setDefaultQuantity(product) {
      if (!product.userQuantity || product.userQuantity < 1) {
        product.userQuantity = 1;
      }
    },
    openDialog(product) {
      this.dialog = true;
      this.selectedProduct = product;
    },
    async submitOrder() {
      if (!this.selectedProduct.userQuantity || this.selectedProduct.userQuantity < 1 || this.selectedProduct.userQuantity > this.selectedProduct.quantity) {
        alert("Invalid quantity");
        this.dialog = false;
        return;
      }
      const fundAvailable = this.userDetails.availableFunds - (this.selectedProduct.price * this.selectedProduct.userQuantity);
      if (fundAvailable < 0) {
        alert("Insufficient fund");
        this.dialog = false;
        return;
      }
      const body = {
        productId: this.selectedProduct._id,
        quantity: this.selectedProduct.userQuantity,
        memberId: this.userId,
      };
      try {
        const response = await axios.post(`${BASE_URL}/merchant/createOrderRequest`, body);
        if (response.status === 200 || response.status === 201) {
          successAlert("Order placed successfully");
        } else {
          failureAlert("Error while placing order", response.data.message);
        }
      } catch (error) {
        console.error(error);
        failureAlert("Error while placing order");
      }

      this.dialog = false;
    }
  }
};

</script>
  
<style scoped>
.headline {
    font-weight: bold;
    text-decoration: underline;
    align-items: center;
    justify-content: center;
}

.product-name {
    font-weight: bold;
    text-decoration: underline;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-card {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.product-card:hover {
    transform: translateY(-5px);
}

.product-image {
    max-width: 100%;
    height: 200px;
    max-height: 200px;
    margin-bottom: 10px;
    border-radius: 5px;
    object-fit: cover;
}

.quantity-input {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.quantity-field {
    width: 60px;
    height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
    margin-left: 10px;
}

.quantity-field:focus {
    border-color: #278ea5;
    outline: none;
}
</style>
  