<template>
  <div>
    <v-main>
      <div v-if="isLoading" class="loader-container">
        <v-progess-circular indeterminate color="primary"> </v-progess-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-center py-1 my-10">
            <h3>Bank Details</h3>
          </v-col>
        </v-row>
        <v-container>
          <v-text-field
            v-model="sponsorName"
            label="Sponsor Name"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="sponsorID"
            label="Sponsor ID"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="sponsorMobileNumber"
            label="Sponsor Mobile Number"
            outlined
          ></v-text-field>
        </v-container>
      </div>
    </v-main>
  </div>
</template>
<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "SponsorDetails",
  data() {
    return {
      isLoading: false,
      sponsorName: "",
      sponsorMobileNumber: "",
      sponsorID: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
    };
  },

  async created() {
    this.memberID = localStorage.getItem("memberId");
    if (!this.memberID) {
      this.$router.push("/login");
      return;
    }
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchSponsorDetails();
    // this.sponsorName
    this.isLoading = false;
  },
  methods: {
    async fetchSponsorDetails() {
      try {
        var res = await axios.get(
          `${BASE_URL}/fund/sponsor_details/${this.memberID}`
        );
        console.log(res.data);
        if (res.status === 200) {
          this.sponsorID = res.data.sponsorId;
          this.sponsorName = res.data.sponsorName;
          this.sponsorMobileNumber = res.data.sponsorMobileNumber;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log("Error while finding bank details");
      }
    },
    //   async postBankDetails() {
    //     try {
    //       this.disableButton = true;
    //       var body = {
    //         accountHolderName: this.accountHolderName,
    //         bankName: this.bankName,
    //         accountNumber: this.accountNumber,
    //         ifscCode: this.ifscCode,
    //         upiId: this.upiID,
    //       };
    //       var res = await axios.post(
    //         `${BASE_URL}/admin/add_bank_details/${this.memberID}`,
    //         body
    //       );
    //       this.disableButton = false;
    //       if (res.status === 200 || res.status === 201) {
    //         this.usdtTrc20 = res.data.USDT_trc20;
    //         Swal.fire({
    //           icon: "success",
    //           title: "Updated successfully",
    //           toast: true,
    //           position: "top-end",
    //           showConfirmButton: false,
    //           timer: 3000,
    //           timerProgressBar: true,
    //           didOpen: (toast) => {
    //             toast.addEventListener("mouseenter", Swal.stopTimer);
    //             toast.addEventListener("mouseleave", Swal.resumeTimer);
    //           },
    //         });
    //         await this.fetchBankDetails(this.memberID);
    //       } else {
    //         throw new Error(res.data.message);
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       Swal.fire({
    //         icon: "error",
    //         title: error.message,
    //         toast: true,
    //         position: "top-end",
    //         showConfirmButton: false,
    //         timer: 3000,
    //         timerProgressBar: true,
    //         didOpen: (toast) => {
    //           toast.addEventListener("mouseenter", Swal.stopTimer);
    //           toast.addEventListener("mouseleave", Swal.resumeTimer);
    //         },
    //       });
    //     }
    //   },
  },
};
</script>
