<template>
  <v-app>
    <SideBar v-if="showSidebar" />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import SideBar from "./components/home/SideBar.vue";

export default {
  name: "App",
  components: { SideBar },
  setup() {
    const route = useRoute();
    const showSidebar = computed(
      () => route.name !== "login" && route.name !== "register" && route.name !=="Delete Account" && route.name !== "landing"
    );
    return { showSidebar };
  },
  data: () => ({
    drawer: null,
  }),
  methods: {},
};
</script>
