<template>
  <div>
    <GridView />
  </div>
</template>

<script>
import GridView from "./GridView.vue";

export default {
  name: "TabsPage",
  components: {
    GridView,
  },
};
</script>
