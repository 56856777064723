<template>
  <div>
    <div class="mb-3">
      <v-col cols="12" class="text-center py-1">
        <h3 class="m-2 my-5 heading">
          Left Team (<span v-if="loadingCounts">Loading....</span>
          <span v-else>{{ leftCount }}</span
          >)
        </h3>
      </v-col>
      <div v-if="isLeftLoading" class="loader-container">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-table class="my-table">
          <thead>
            <tr>
              <th class="my-table-header" v-for="key in keys" :key="key">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody v-if="leftValues.length > 0">
            <tr v-for="(value, index1) in leftValues" :key="index1">
              <td
                class="my-table-cell"
                v-for="(item, index) in value"
                :key="index"
              >
                {{ index === 0 ? index1 + 1 + (leftTeam - 1) * 10 : item }}
                <!-- <span v-if="index === 1"
                  ><v-btn
                    flat
                    class="ml-2"
                    small
                    icon
                    @click="copyToClipboard(item)"
                  >
                    <v-icon class="flat-icon">mdi-content-copy</v-icon>
                  </v-btn></span
                > -->
              </td>
            </tr>
          </tbody>
          <div v-else class="no-data mt-5">No data found</div>
        </v-table>
      </div>
      <v-pagination
        v-if="leftValues.length > 0"
        v-model="leftTeam"
        :length="leftTeamPageLimit"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @click="fetchLeftMyTeam(true, false)"
      ></v-pagination>
    </div>
    <div class="mb-3">
      <v-col cols="12" class="text-center py-1">
        <h3 class="m-2 my-5 heading">
          Right Team (<span v-if="loadingCounts">Loading....</span>
          <span v-else>{{ rightCount }}</span
          >)
        </h3>
      </v-col>
      <div v-if="isRightLoading" class="loader-container">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-table class="my-table">
          <thead>
            <tr>
              <th class="my-table-header" v-for="key in keys" :key="key">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody v-if="rightValues.length > 0">
            <tr v-for="(value, index1) in rightValues" :key="index1">
              <td
                class="my-table-cell"
                v-for="(item, index) in value"
                :key="index"
              >
                {{ index === 0 ? index1 + 1 + (rightTeam - 1) * 10 : item }}
                <!-- <span v-if="index === 1"
                  ><v-btn
                    flat
                    class="ml-2"
                    small
                    icon
                    @click="copyToClipboard(item)"
                  >
                    <v-icon class="flat-icon">mdi-content-copy</v-icon>
                  </v-btn></span
                > -->
              </td>
            </tr>
          </tbody>
          <div v-else class="no-data mt-5">No data found</div>
        </v-table>
      </div>
      <v-pagination
        v-if="rightValues.length > 0"
        v-model="rightTeam"
        :length="rightTeamPageLimit"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @click="fetchLeftMyTeam(false, true)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
// import axios from "axios"
axios.defaults.withCredentials = true;
import { BASE_URL } from "@/constants";
// export default {
//   name: "GridView",
//   data() {
//     return {
//       leftTeam: 1,
//       rightTeam: 1,
//       leftTeamPageLimit: 1,
//       rightTeamPageLimit: 1,
//       leftCount: 0,
//       rightCount: 0,
//       loadingCounts: false,
//       keys: [
//         "SNo",
//         "MemberID",
//         "Placement ID",
//         "Sponsor ID",
//         "Name",
//         "Direction",
//         "Status",
//         "Join Date",
//         "Activated Date",
//       ],
//       leftValues: [],
//       rightValues: [],
//       isLeftLoading: false,
//       isRightLoading: false,
//       memberId: "",
//     };
//   },
//   async created() {
//     this.memberId = localStorage.getItem("memberId");
//     if (!this.memberId) {
//       this.$router.push("/login");
//       return;
//     }
//     await this.fetchLeftMyTeam(true, true);
//     await this.fetchCounts();
//   },
//   methods: {
//     async fetchCounts() {
//       try {
//         this.loadingCounts = true;
//         const response = await axios.get(
//           `${BASE_URL}/fund/teamCounts/${this.memberId}`
//         );
//         console.log(response.data);
//         this.leftCount = parseInt(response.data.leftCount);
//         this.rightCount = parseInt(response.data.rightCount);
//         this.leftTeamPageLimit = Math.ceil(
//           parseInt(response.data.leftCount) / 10
//         );
//         this.rightTeamPageLimit = Math.ceil(
//           parseInt(response.data.rightCount) / 10
//         );
//       } catch (error) {
//         console.log(error);
//       }
//       this.loadingCounts = false;
//     },
//     async fetchLeftMyTeam(left, right) {
//       let page = 0;
//       if (left) {
//         this.isLeftLoading = true;
//         page = this.leftTeam;
//       }
//       if (right) {
//         this.isRightLoading = true;
//         page = this.rightTeam;
//       }
//       try {
//         const response = await axios.get(
//           `${BASE_URL}/fund/show_left_right_teams/${this.memberId}?page=${page}&limit=10`
//         );
//         console.log(response.data);
//         if (left && response.data.left.length > 0)
//           this.leftValues = response.data.left;
//         if (right && response.data.right.length > 0)
//           this.rightValues = response.data.right;
//       } catch (error) {
//         console.log(error);
//       }
//       if (left) this.isLeftLoading = false;
//       if (right) this.isRightLoading = false;
//     },
//     copyToClipboard(text) {
//       navigator.clipboard
//         .writeText(text)
//         .then(() => {})
//         .catch((err) => {
//           console.error("Could not copy text: ", err);
//         });
//     },
//   },
// };
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "GridView",
  data() {
    return {
      leftTeam: 1,
      rightTeam: 1,
      leftTeamPageLimit: 1,
      rightTeamPageLimit: 1,
      leftCount: 0,
      rightCount: 0,
      loadingCounts: false,
      keys: [
        "SNo",
        "MemberID",
        "Name",
        "Placement ID",
        "Name",
        "Sponsor ID",
        "Name",
        "Direction",
        "Status",
        "Join Date",
        "Activated Date",
      ],
      leftValues: [],
      rightValues: [],
      isLeftLoading: false,
      isRightLoading: false,
      memberId: "",
    };
  },
  async created() {
    this.memberId = localStorage.getItem("memberId");
    if (!this.memberId) {
      this.$router.push("/login");
      return;
    }
    await this.initializeData();
  },
  methods: {
    async initializeData() {
      await this.fetchLeftMyTeam(true, true);
      await this.fetchCounts();
    },
    async fetchCounts() {
      this.loadingCounts = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/teamCounts/${this.memberId}`
        );
        const { leftCount, rightCount } = response.data;
        this.leftCount = parseInt(leftCount);
        this.rightCount = parseInt(rightCount);
        this.leftTeamPageLimit = Math.ceil(this.leftCount / 10);
        this.rightTeamPageLimit = Math.ceil(this.rightCount / 10);
      } catch (error) {
        console.error("Error fetching counts:", error);
      } finally {
        this.loadingCounts = false;
      }
    },
    async fetchLeftMyTeam(fetchLeft = false, fetchRight = false) {
      if (!fetchLeft && !fetchRight) return;

      const promises = [];
      if (fetchLeft) {
        this.isLeftLoading = true;
        promises.push(this.fetchTeamData("left", this.leftTeam));
      }
      if (fetchRight) {
        this.isRightLoading = true;
        promises.push(this.fetchTeamData("right", this.rightTeam));
      }

      await Promise.all(promises);

      this.isLeftLoading = false;
      this.isRightLoading = false;
    },
    async fetchTeamData(direction, page) {
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/show_left_right_teams/${this.memberId}?page=${page}&limit=10`
        );
        const data = response.data[direction];
        if (data && data.length > 0) {
          if (direction === "left") {
            this.leftValues = data;
          } else {
            this.rightValues = data;
          }
        }
      } catch (error) {
        console.error(`Error fetching ${direction} team data:`, error);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text:", err);
        });
    },
  },
};
</script>
<style scoped>
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table-header {
  background-color: #278ea5;
  border: 1px solid #dee2e6;
  color: white;
  font-weight: bold;
  padding: 0.75rem;
  text-align: center;
}

.my-table-cell {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: left;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.flat-icon {
  box-shadow: none !important;
}

.heading {
  color: #278ea5;
  text-decoration: underline;
}
</style>
