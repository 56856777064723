<template>
  <v-col cols="12" class="text-center py-1">
    <h2 class="m-2 text-decoration-underline">Fund Transfer History</h2>
  </v-col>
  <div v-if="isLoading" class="loader-container">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <div v-else>
    <table class="my-table mt-5">
      <thead>
        <tr>
          <th class="my-table-header" v-for="key in keys" :key="key">
            {{ key === "State" ? "Status" : key }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in values" :key="index">
          <td class="my-table-cell" v-for="(item, index) in value" :key="index">
            {{ item }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "FundTransferHistory",
  data() {
    return {
      keys: [],
      values: [],
      isLoading: false,
      memberId: "",
    };
  },
  async created() {
    this.memberId = localStorage.getItem("memberId");
    if (!this.memberId) {
      this.$router.push("/login");
    }
    await this.fetchFundRequestHistory();
  },
  methods: {
    async fetchFundRequestHistory() {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${BASE_URL}/fund/f_to_f_transfer_history/${this.memberId}`
        );
        if (response.status === 200) {
          this.keys = response.data.keys;
          this.values = response.data.values;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table-header {
  background-color: #278ea5;
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.my-table-cell {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: center;
  justify-content: center;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
