import { createRouter, createWebHistory } from "vue-router";
import HomeComponent from "@/components/home/Home.vue";
import ActivateUser from "@/components/sidebar/topUp/ActivateUser.vue";
import NewFundRequest from "@/components/sidebar/fundRequest/NewFundRequest.vue";
import NewFundTransfer from "@/components/sidebar/fundTransfer/NewFundTransfer.vue";
import NewWalletToFundTransfer from "../components/sidebar/walletToFundTransfer/NewWalletToFundTransfer.vue";
import WalletToFundTransferHistory from "../components/sidebar/walletToFundTransfer/History.vue";
import TopUpHistory from "../components/sidebar/topUp/History.vue";
import FundRequestHistory from "../components/sidebar/fundRequest/History.vue";
import FundTransferHistory from "../components/sidebar/fundTransfer/History.vue";
import IncomeHistory from "../components/sidebar/income/History.vue";
import NewRegistration from "@/components/sidebar/member/NewRegistration.vue";
// import GridView from "@/components/sidebar/myTeam/GridView.vue";
import LoginView from "@/components/Login.vue";
import NewWithdrawlPage from "@/components/sidebar/withdrawl/NewWithdrawlPage.vue";
import ProfilePage from "@/components/sidebar/Profile.vue";
import BankDetails from "@/components/sidebar/profilePages/BankDetails.vue";
import WithdrawlHistory from "@/components/sidebar/withdrawl/History.vue";
import UpdateSecurityPin from "@/components/sidebar/profilePages/UpdateSecurityPin.vue";
import NewSupportRequest from "@/components/sidebar/support/NewSupportRequest.vue";
import SupportHistory from "@/components/sidebar/support/History.vue";
import DeleteAccount from "@/components/Delete.vue";
import DirectTeam from "@/components/sidebar/myTeam/DirectTeam.vue";
import UpdatePassword from "@/components/sidebar/profilePages/UpdatePassword.vue";
import TabsPage from "@/components/sidebar/myTeam/Tabs.vue";
import Tabslevel from "@/components/sidebar/myTeam/Tabslevel.vue";
import SponsorDetails from "@/components/sidebar/profilePages/SponsorDetails.vue";
import AddressDetails from "@/components/sidebar/profilePages/Address.vue";
import LandingPage from "@/components/Landing.vue";
import RegisterPage from "@/components/Register.vue";
import BuyRequests from "@/components/merchant/BuyRequests.vue";
import ProductList from "@/components/Products/ProductList.vue";
import ProductDetail from "@/components/Products/ProductDetail.vue";
import ProductStore from "@/components/merchant/ProductStore.vue"
import MerchantStore from "@/components/merchant/MerchantStore.vue"
import MerchantOrderHistory from "@/components/merchant/OrderHistory.vue"
import CustomerOrderHistory from "@/components/sidebar/CustomerOrders.vue"
const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingPage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/home",
    name: "home",
    component: HomeComponent,
  },
  {
    path: "/buy_coupon",
    name: "ActivateUser",
    component: ActivateUser,
  },
  {
    path: "/new_fund_request",
    name: "Fund Request",
    component: NewFundRequest,
  },
  {
    path: "/p2p_transfer",
    name: "P2P Transfer",
    component: NewFundTransfer,
  },
  {
    path: "/wallet_to_fund",
    name: "Wallet To Fund Transfer",
    component: NewWalletToFundTransfer,
  },
  {
    path: "/wallet_to_fund_history",
    name: "Wallet To Fund History",
    component: WalletToFundTransferHistory,
  },
  {
    path: "/coupon_history",
    name: "Top Up History",
    component: TopUpHistory,
  },
  {
    path: "/fund_request_history",
    name: "Fund Request History",
    component: FundRequestHistory,
  },
  {
    path: "/fund_transfer_history",
    name: "Fund Transfer History",
    component: FundTransferHistory,
  },
  {
    path: "/income_history",
    name: "Income History",
    component: IncomeHistory,
  },
  {
    path: "/new_registration",
    name: "New Registration",
    component: NewRegistration,
  },
  {
    path: "/grid_view",
    name: "Team View",
    component: TabsPage,
  },
  {
    path: "/new_withdrawl",
    name: "New Withdrawl",
    component: NewWithdrawlPage,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
  },
  {
    path: "/profile/bank_details",
    name: "Bank Details",
    component: BankDetails,
  },
  {
    path: "/withdrawl_history",
    name: "Withdrawl History",
    component: WithdrawlHistory,
  },
  {
    path: "/profile/update_security_pin",
    name: "Update Security Pin",
    component: UpdateSecurityPin,
  },
  {
    path: "/profile/update_password",
    name: "Update Password",
    component: UpdatePassword,
  },
  {
    path: "/new_support_request",
    name: "New Support Request",
    component: NewSupportRequest,
  },
  {
    path: "/support_history",
    name: "Support History",
    component: SupportHistory,
  },
  {
    path: "/delete",
    name: "Delete Account",
    component: DeleteAccount,
  },
  {
    path: "/direct_team",
    name: "Direct Team",
    component: DirectTeam,
  },
  {
    path: "/tabs",
    name: "Tabs",
    component: TabsPage,
  },
  {
    path: "/tabslevel",
    name: "Tabs Level",
    component: Tabslevel,
  },
  {
    path: "/profile/sponsor_details",
    name: "Sponsor Details",
    component: SponsorDetails,
  },
  {
    path: "/profile/address",
    name: "Address Details",
    component: AddressDetails,
  },
  {
    path: "/buy_requests",
    name: "Buy Requests",
    component: BuyRequests,
  },
  {
    path: "/product_list",
    name: "productList",
    component: ProductList, // Set the component for this route
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    component: ProductDetail,
    props: true,
  },
  {
    path:"/product_store",
    name:"Product Store",
    component:ProductStore
  },
  {
    path:"/merchant_store",
    name:"Merchant Store",
    component:MerchantStore
  },
  {
    path:"/merchant_order_history",
    name:"Merchant Order History",
    component:MerchantOrderHistory
  },
  {
    path:"/customer_order_history",
    name:"Customer Order History",
    component:CustomerOrderHistory
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (to.name === "Delete Account") {
    // Allow access to "Delete Account" route regardless of login status
    return next();
  }

  if (isLoggedIn) {
    // If logged in, redirect to home if trying to access "login" or "landing"
    if (to.name === "login" || to.name === "landing") {
      return next({ name: "home" });
    }
  } else {
    // If not logged in, redirect to "login" unless trying to access "login" or "landing"
    if (to.name !== "login" && to.name !== "landing") {
      if (to.name === "register") {
        return next();
      }
      return next({ name: "login" });
    }
  }

  // Continue to the intended route
  next();
});

export default router;
