<template>
  <div>
    <v-main>
      <v-container>
        <div v-if="isLoading" class="loader-container">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-container fluid>
            <v-card elevation="5" class="mx-auto responsive-card" max-width="">
              <v-card-text>
                <v-row justify="center" class="my-0 py-0">
                  <v-col cols="12" class="text-center py-1">
                    <h2 class="text-decoration-underline">Buy Coupon</h2>
                  </v-col>
                  <v-col cols="12" class="text-center py-3">
                    <h4>
                      Available Fund : ₹ {{ userDetails["availableFunds"] }}
                    </h4>
                  </v-col>

                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="memberID2"
                      label="Enter MemberID"
                      outlined
                      @input="fetchParentName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="parentName"
                      label="Member Name"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="password"
                      label="Transaction Password"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      color="#278EA5"
                      dark
                      @click="activateUser"
                      :disabled="disableButton"
                    >
                      Buy Coupon
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
// import axios from 'axios';
import { mapState, mapActions } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import Swal from "sweetalert2";
import { BASE_URL } from "@/constants";
export default {
  name: "ActivateUser",

  data() {
    return {
      isLoading: false,
      memberID: "",
      password: "",
      parentName: "",
      memberID2: "",
      disableButton: false,
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  async created() {
    this.memberID = localStorage.getItem("memberId");
    if (!this.memberID) {
      this.$router.push("/login");
      return;
    }
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchUserDetails(this.memberID);
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),

    async activateUser() {
      try {
        this.disableButton = true;
        if (this.password !== this.userDetails["securityPin"]) {
          alert("Incorrect Transaction Password");
          return;
        }
        const body = {
          parentMid: this.userId, // Replace with the actual user ID
          mId: this.memberID2,
          securityPin: this.password,
          amount: 300,
        };
        const res = await axios.post(
          `${BASE_URL}/fund/top_up_user`,

          body
        ); // Replace with your actual route

        if (res.status === 200) {
          this.isLoading = false;
          this.memberID2 = "";
          this.password = "";
          this.parentName = "";
          Swal.fire({
            icon: "success",
            text: "Activated successfully",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          await this.fetchUserDetails(this.memberID);
        } else {
          this.isLoading = false;
          throw new Error(res.data.message);
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          text: e.response.data.message,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.memberID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.memberID2}`
          );
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },
  },
};
</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.responsive-card {
  width: 100%; /* This will apply a 5% margin on both sides on mobile */
  max-width: 700px; /* This will restrict the width to 700px on larger screens */
}

/* For screens larger than 600px, we can increase the width to 95% */
@media (min-width: 600px) {
  .responsive-card {
    width: 95%;
  }
}
</style>
