<template>
  <div>
    <v-main>
      <div v-if="isLoading" class="loader-container">
        <v-progess-circular indeterminate color="primary"> </v-progess-circular>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-center py-1 my-10">
            <h3>Update Password</h3>
          </v-col>
        </v-row>
        <v-container>
          <v-text-field
            v-model="oldPassword"
            label="Old Password"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="newPassword"
            label="New Password"
            outlined
          ></v-text-field>
          <v-col cols="12" class="text-center py-1">
            <v-btn
              color="#278EA5"
              dark
              @click="UpdatePassword"
              :disabled="disableButton"
            >
              Update
            </v-btn>
          </v-col>
        </v-container>
      </div>
    </v-main>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL, failureAlert, successAlert } from "@/constants";
export default {
  name: "UpdatePassword",
  data() {
    return {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      disableButton: false,
      memberID: localStorage.getItem("memberId"),
    };
  },
  async created() {
    this.memberID = localStorage.getItem("memberId");
    this.isLoading = true;
    this.fetchUserDetails(this.memberID);
    this.isLoading = false;
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    async UpdatePassword() {
      try {
        if (!this.oldPassword || !this.newPassword) {
          alert("Please fill all the fields");
          return;
        }
        // if (this.oldPassword.length < 4) {
        //   alert("Old pin should be atleast 4 characters long");
        //   return;
        // }
        // if (this.newPassword.length < 4) {
        //   alert("New pin should be atleast 4 characters long");
        //   return;
        // }
        if (this.oldPassword === this.newPassword) {
          alert("passwords cannot be same");
          return;
        }
        this.disableButton = true;
        var body = {
          currentPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        var res = await axios.post(
          `${BASE_URL}/auth/update_password/${this.memberID}`,
          body
        );
        if (res.status === 200 || res.status === 201) {
          successAlert("Updated successfully");
          this.oldPassword = "";
          this.newPassword = "";
          this.isLoading = true;
          await this.fetchUserDetails(this.memberID);
          this.isLoading = false;
          this.disableButton = false;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        failureAlert("Sorry","Failed to update password");
        this.disableButton = false;
      }
    },
  },
};
</script>
