<template>
    <div class="m-5">
        <v-row class="d-flex align-center justify-center">
            <h1 class="text-center m-5 heading">Requests</h1>
        </v-row>
        <div v-if="isLoading" class="loader-container">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else>
            <v-row class="d-flex align-center justify mb-5">
                <v-spacer></v-spacer>
                <p>Select Type</p>
                <v-col cols="6" class="background-color:blue ">
                    <select name="select type" id="select type" v-model="selectedType" @change="updateCompletedRequests()"
                        :style="{ backgroundColor: 'grey', color: 'white' }">
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Delivered">Delivered</option>
                    </select>
                </v-col>
            </v-row>
            <MerchantTable v-if="selectedType === 'Pending'" :rows="data" :headers="merchantTableKeys" :actions="{
                accept: handleAccept,
                reject: handleReject
            }" />
            <MerchantTable v-else-if="selectedType === 'Approved'" :rows="data" :headers="merchantTableKeys" :actions="{
                deliver: handleDeliver
            }" />
            <MerchantTable v-else :rows="data" :headers="merchantTableKeys" />
        </div>
    </div>
</template>

<script>
import { BASE_URL, failureAlert, successAlert } from "@/constants";
import MerchantTable from "./MerchantTable.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
    name: "BuyRequests",
    components: { MerchantTable },
    data() {
        return {
            merchantTableKeys: ["SNo", "Name", "address", "phone", "img", "quantity", "amount"],
            fetchingProducts: false,
            pendingBuyRequests: [],
            acceptedBuyRequests: [],
            rejectedBuyRequests: [],
            deliveredBuyRequests: [],
            data: [],
            productList: [],
            types: ["Rejected", "Delivered", "Pending", "Approved"],
            defaultType: "Pending",
            selectedType: "Pending",
            isLoading: false
        }
    },
    async created() {
        this.memberId = localStorage.getItem("memberId");
        if (!this.memberId) {
            this.$router.push("/login");
            return;
        }
        await this.fetchUserDetails(this.memberId);
        this.isLoading = true;
        await this.fetchMerchantRequests();
        this.updateCompletedRequests();
        this.isLoading = false;
    },
    computed: {
        ...mapState(["userDetails", "userId", "userName"]),
    },
    methods: {
        ...mapActions(["fetchUserDetails"]),
        async fetchMerchantRequests(defaultType = true) {
            try {
                const response = await axios.get(`${BASE_URL}/merchant/customer/fetchOrders/${this.memberId}`);
                console.log(response.data);
                if (response.status === 200 || response.status === 201) {
                    this.pendingBuyRequests = response.data.pending_orders;
                    this.acceptedBuyRequests = response.data.approved_orders;
                    this.rejectedBuyRequests = response.data.rejected_orders;
                    this.deliveredBuyRequests = response.data.delivered_orders;
                    if (defaultType)
                        this.data = response.data.pending_orders;
                }
                console.log("accpeted ", this.acceptedBuyRequests);
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while fetching requests");
            }
        },
        async fetchAllProducts() {
            // Call API to fetch all products
            try {
                const response = await axios.get(`${BASE_URL}/admin/products_available`);
                this.productList = response.data;
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while fetching products");
            }
        },
        async handleAccept(requestId) {
            // Call API to accept the request
            console.log(`Accepted request ID: ${requestId}`);
            try {
                const resonse = await axios.get(`${BASE_URL}/merchant/customer/approveOrder/${requestId}`);
                console.log(resonse);
                if (resonse.status === 200 || resonse.status === 201) {
                    successAlert("Request Accepted");
                    this.fetchMerchantRequests();
                }
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while accepting request");

            }
        },
        async handleReject(requestId) {
            console.log(`Rejected request ID: ${requestId}`);
            try {
                const resonse = await axios.get(`${BASE_URL}/merchant/customer/rejectOrder/${requestId}`);
                if (resonse.status === 200 || resonse.status === 201) {
                    successAlert("Request Rejected");
                    this.fetchMerchantRequests();
                }
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while rejecting request");

            }
        },

        async handleDeliver(requestId) {
            try {
                console.log(`Delivering request ID: ${requestId}`);
                const response = await axios.get(`${BASE_URL}/merchant/customer/devilerOrder/${requestId}`);
                if (response.status === 200 || response.status === 201) {
                    successAlert("Request Delivered");
                    this.isLoading = true;
                    await this.fetchMerchantRequests(false);

                    this.isLoading = false;
                } else {
                    failureAlert("Sorry", response.data.message);
                }
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", error.response.data.message);
            }
        },
        updateCompletedRequests() {
            console.log("type:  ", this.selectedType);
            if (this.selectedType === "Rejected") {
                this.data = this.rejectedBuyRequests;
            } else if (this.selectedType === "Delivered") {
                this.data = this.deliveredBuyRequests;
            } else if (this.selectedType === "Approved") {
                this.data = this.acceptedBuyRequests;
            } else {
                this.data = this.pendingBuyRequests;
            }
        }
    }
}
</script>

<style>
.heading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: underline;
}

.my-table-header {
    background-color: #278ea5;
    border: 1px solid #dee2e6;
    color: white;
    font-weight: bold;
    padding: 0.75rem;
    text-align: center;
}

.my-table {
    width: 100%;
    border-collapse: collapse;
}

.my-table-cell {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    text-align: left;
}

.my-table-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.accept-button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.reject-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.accept-button:hover,
.reject-button:hover {
    opacity: 0.8;
}

select {
    width: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

select:focus {
    border-color: #278ea5;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    outline: none;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}</style>
