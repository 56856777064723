<template>
    
    <!-- <HeaderComponent/> -->
      <NavbarComponent/>
      <BodyPart1/>
      <BodyPart2 />
      <!-- <BodyPart3 /> -->
      <FooterComponent />
    
  </template>
  
  <script>
    // import HeaderComponent from './Landing/Header.vue'
    import NavbarComponent from './Landing/Navbar'
    import BodyPart1 from './Landing/Body1'
    import FooterComponent from './Landing/Footer'
    import BodyPart2 from './Landing/Products'
    export default {
      name: 'LandingPage',
      components: {
        // HeaderComponent,
        NavbarComponent,
        BodyPart1,
        BodyPart2,
        FooterComponent
  
      },
    }
  </script>
  <style scoped>
  .index{
    position: fixed;
    max-width: 100%;
  }
  </style>
  