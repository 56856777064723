<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row justify="center" class="login-container">
        <!-- Login Form -->
        <v-col cols="12" md="6" key="login-form" v-if="loginPage">
          <v-card class="elevation-12 login-card">
            <v-toolbar dark flat class="d-flex justify-center">
              <v-toolbar-title>Login to Your Account</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-text-field
                  label="Enter your MemberId"
                  :rules="[
                    (v) =>
                      (!!v && v.length == 10) ||
                      'MemberId must be 10 characters long',
                  ]"
                  v-model="phoneNo"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Password"
                  v-model="password"
                  :rules="[
                    (v) =>
                      (v && v.length >= 6) ||
                      'Password must be at least 6 characters',
                  ]"
                  :type="'password'"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-row class="text-center justify-space-between mt-5">
                  <v-btn
                    color="primary"
                    dark
                    @click="login"
                    :disabled="disableButton || !valid"
                    class="v-btn-custom move-right"
                  >
                    <p v-if="disableButton">Logging in...</p>
                    <p v-else>Login</p>
                  </v-btn>
                  <v-btn
                    text
                    @click="(forgotPasswordPage = true), (loginPage = false)"
                    class="v-btn-text move-left"
                  >
                    Forgot Password
                  </v-btn>
                </v-row>
              </v-form>
              <div class="text-center m-3">
                <p>
                  Don't have an account?
                  <router-link to="/register">
                    <b>Register Here</b></router-link
                  >
                </p>
              </div>
              <div class="text-center mt-3">
                <p>
                  <router-link to="/"> <b>Back To Home</b></router-link>
                </p>
              </div>

              <div class="text-center mt-3">
                <v-btn
                  text
                  @click="(supportPage = true), (loginPage = false)"
                  class="v-btn-text"
                >
                  Contact Support Here
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Forgot Password Form -->
        <v-col
          cols="12"
          md="6"
          key="forgot-password-form"
          v-else-if="forgotPasswordPage"
        >
          <v-card class="elevation-12 login-card">
            <v-toolbar dark flat>
              <v-toolbar-title>Recover Your Account Password</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="forgotPasswordForm" v-model="valid" lazy-validation>
                <v-text-field
                  label="Enter your memberId"
                  :rules="[
                    (v) =>
                      (!!v && v.length >= 10) ||
                      'MemberId must be 10 characters long',
                  ]"
                  v-model="phoneNo"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Enter Your Registered Email"
                  v-model="email"
                  :type="'email'"
                  :rules="[
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-row class="align-center justify-center mt-5">
                  <v-btn
                    color="primary"
                    dark
                    @click="resetPassword"
                    :disabled="disableButton || !valid"
                    class="v-btn-custom"
                  >
                    <p v-if="disableButton">Resetting...</p>
                    <p v-else>Reset Password</p>
                  </v-btn>
                  <v-btn
                    text
                    @click="(loginPage = true), (forgotPasswordPage = false)"
                    class="v-btn-text space-left"
                  >
                    Back to Login
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Support Page -->
        <v-col cols="12" md="6" key="support-page" v-else-if="supportPage">
          <v-card class="elevation-12 login-card">
            <v-toolbar dark flat>
              <v-toolbar-title>Have any issues in Logging In?</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row class="align-center justify-center mt-5 mb-5">
                <h4>We are here to help you.</h4>
              </v-row>
              <v-form ref="supportForm" v-model="valid" lazy-validation>
                <v-text-field
                  label="Enter your memberId"
                  :rules="[
                    (v) =>
                      (!!v && v.length == 10) ||
                      'MemberId must be 10 characters long',
                  ]"
                  v-model="phoneNo"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Enter Subject (e.g., Unable to Login, Register, Etc.)"
                  v-model="subject"
                  outlined
                  dense
                ></v-text-field>
                <v-textarea
                  label="Describe Problem"
                  v-model="description"
                  outlined
                  dense
                ></v-textarea>
                <v-row class="align-center justify-center mt-5">
                  <v-btn
                    color="primary"
                    dark
                    @click="submitRequest"
                    :disabled="disableButton"
                    class="v-btn-custom"
                  >
                    <p v-if="disableButton">Submitting...</p>
                    <p v-else>Submit</p>
                  </v-btn>
                </v-row>
                <v-row class="align-center justify-center mt-5">
                  <v-btn
                    text
                    @click="(loginPage = true), (supportPage = false)"
                    class="v-btn-text"
                  >
                    Back to Login
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Register Form -->
        <v-col cols="12" md="6" key="register-form" v-else-if="registerPage">
          <v-card class="elevation-12 login-card">
            <v-toolbar dark flat>
              <v-toolbar-title>New Registration</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="registerForm" v-model="valid" lazy-validation>
                <v-text-field
                  label="Name"
                  v-model="name"
                  :rules="[(v) => (!!v && v.length > 2) || 'Name is required']"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Phone Number"
                  v-model="phone"
                  :rules="[
                    (v) =>
                      (!!v && v.length >= 10 && /^\d+$/.test(v)) ||
                      'Phone number must be greater than 9 digits and contain only numbers',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Password"
                  v-model="password"
                  :rules="[
                    (v) =>
                      (v && v.length >= 6) ||
                      'Password must be at least 6 characters',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Email"
                  v-model="email"
                  :type="'email'"
                  :rules="[
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Referral ID"
                  v-model="referalID"
                  :type="'text'"
                  :rules="textRules"
                  @input="fetchParentName"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  label="Referral Name"
                  v-model="parentName"
                  :type="'text'"
                  :rules="textRules"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-radio-group
                  v-model="direction"
                  row
                  class="radio-group"
                  color="primary"
                >
                  <v-row>
                    <v-radio label="Left" value="Left"></v-radio>
                    <v-radio label="Right" value="Right"></v-radio>
                  </v-row>
                </v-radio-group>
                <v-text-field
                  label="Security PIN"
                  v-model="securityPin"
                  :type="'password'"
                  :rules="textRules"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-btn
                  color="primary"
                  dark
                  @click="register"
                  :disabled="disableButton || !valid"
                  class="v-btn-custom"
                  style="margin: 0 auto; display: block"
                >
                  <p v-if="disableButton">Registering...</p>
                  <p v-else>Register</p>
                </v-btn>
              </v-form>
              <v-row class="align-center justify-center mt-5">
                <v-btn
                  text
                  @click="(loginPage = true), (registerPage = false)"
                  class="v-btn-text"
                >
                  Back to Login
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style scoped>
.login-container {
  animation: fadeIn 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.move-right {
  margin-left: 20px;
}
.move-left {
  margin-left: 20px;
}
.space-left {
  margin-left: 20px;
}
.align-center {
  display: flex;
  justify-content: center;
}
.toolbar-center {
  display: flex;
  justify-content: center;
}

.v-toolbar {
  background-color: #278ea5 !important;
}

.login-card {
  border-radius: 5px;
  overflow: hidden;
}

.v-btn {
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.v-btn:hover {
  background-color: #1b5f6b !important;
  transform: translateY(-2px);
}

.v-btn-text {
  color: #278ea5 !important;
}

.v-btn-custom {
  background-image: linear-gradient(to right, #43cea2, #185a9d);
  color: white;
}

.login-container h4 {
  color: #185a9d;
}

.login-container p {
  color: #333;
}

.v-text-field,
.v-textarea {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
}

.radio-group {
  justify-content: space-between;
}

.v-text-field::placeholder,
.v-textarea::placeholder {
  color: #bbb;
}

.v-card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.v-toolbar-title {
  font-weight: bold;
  font-size: 1.2em;
}

.v-radio-group {
  padding: 10px 0;
}
</style>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
import {successAlert,failureAlert,BASE_URL} from "../constants"
export default {
  name: "LoginView",
  created() {
    this.referalID = this.$route.query.referalID || "";
    this.direction = this.$route.query.direction || "Left";
    if (this.referalID.length !== 0) {
      this.loginPage = false;
      this.registerPage = true;
      console.log("referalId exists in query string");
      this.fetchParentName();
    }
  },
  data: () => ({
    loginPage: true,
    description: "",
    subject: "",
    forgotPasswordPage: false,
    passwordResetted: false,
    passwordResetError: false,
    registerPage: false,
    supportPage: false,
    isLoading: false,
    disableButton: false,
    valid: true,
    phoneNo: "",
    password: "",
    name: "",
    phone: "",
    referalID: "",
    parentName: "",
    direction: "Left",
    email: "",
    securityPin: "",
  }),
  methods: {
    async login() {
      this.isLoading = true;
      this.disableButton = true;
      try {
        var body = {
          memberId: this.phoneNo,
          password: this.password,
        };
        const response = await axios.post(
          `${BASE_URL}/auth/login`,
          body
        );
        this.isLoading = false;
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("memberId", response.data.memberId);
          console.log(localStorage.getItem("memberId"));
          localStorage.setItem("isLoggedIn", true);

          successAlert("Signed in successfully");

          // Redirect to home page
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        console.log(error);
        failureAlert("Failed",error.response.data.message);
        this.isLoading = false;
        this.disableButton = false;
        (this.phoneNo = ""),
          (this.password = ""),
          this.$router.push({ name: "login" });
      }
    },
    async resetPassword() {
      try {
        var body = {
          email: this.email,
          memberId: this.phoneNo,
        };
        const response = await axios.put(
          `${BASE_URL}/auth/reset_password`,
          body
        );
        if (response.status === 200 || response.status === 201) {
          successAlert("Password Reset");
          this.passwordResetted = true;
        } else {
          this.passwordResetError = true;
        }
      } catch (error) {
        failureAlert("Error while resetting password",error.response.data.message);
        this.passwordResetError = true;
      }
      this.email = "";
    },
    async register() {
      try {
        this.disableButton = true;
        this.loginPage = false;
        var body = {
          name: this.name,
          password: this.password.trim(),
          phoneNo: this.phone.trim(),
          email: this.email.trim(),
          referralCode: this.referalID.trim(),
          position: this.direction,
          securityPin: this.securityPin.trim(),
          isMerchant:false
        };
        const response = await axios.post(
          `${BASE_URL}/auth/register`,
          body
        );
        if (response.status === 200 || response.status === 201) {
          successAlert("Registered successfully");
          this.isLoading = false;
          localStorage.setItem("memberId", response.data.memberId);
          localStorage.setItem("isLoggedIn", true);
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        failureAlert("Registration Failed",error.response.data.message);
      }
      this.disableButton = false;
    },
    async fetchParentName() {
      if (this.referalID.length === 10) {
        try {
          const response = await axios.get(
            `${BASE_URL}/auth/parent_name/${this.referalID}`
          );
          console.log(response.data);
          this.parentName = response.data.name;
        } catch (error) {
          console.error(error);
          this.parentName = "";
        }
      }
    },
    async submitRequest() {
      try {
        this.disableButton = true;
        var body = {
          memberId: this.phoneNo,
          description: this.description,
          subject: this.subject,
        };
        var res = await axios.post(
          `${BASE_URL}/fund/support/new_request`,
          body
        );
        if (res.status === 200 || res.status === 201) {
          successAlert("We Will solve issue soon");
          this.subject = "";
          this.description = "";
          this.phoneNo = "";
        }
      } catch (err) {
        console.log(err);
        failureAlert("Sorry",err.response.data.message);
      }

      this.disableButton = false;
    },
  },
};
</script>
<style scoped>
.rounded-img {
  border-radius: 20%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
