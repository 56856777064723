<template>
  <nav class="header">
      <div class="container flex ">
          <div class="flex items-center justify-between flex-grow md:pl-12 py-4 ">
              <div class="flex items-center space-x-6 capitalize text-white text-xl">
                  <router-link to="/" class=" hover:text-gray-300 transition">VcanShoppe</router-link>
                  <!-- <router-link to="/shopView" class=" hover:text-gray-300 transition">Shop</router-link> -->
                  <router-link v-if="isLoggedIn" to="/orderView" class=" hover:text-gray-300 transition">My Order</router-link>
              </div>
              <div>
                  <div v-if="!isLoggedIn">
                      <router-link  to="/login" class="text-white hover:text-gray-300 text-xl  transition">Login</router-link>
                      <span class="text-white text-xl mx-2">/</span>
                      <router-link to="/register" class="text-white hover:text-gray-300 text-xl  transition ">Register</router-link>
                  </div>
                  <div @click="logout" v-if="isLoggedIn" class="text-white hover:text-gray-300 text-xl  transition ">Logout</div>
              </div>
          </div>
      </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
  methods: {
    redirectToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style>
.v-toolbar__title {
  font-size: 1rem !important;
}
.v-badge__badge {
  font-size: 10px !important;
  height: 18px !important;
  min-width: 18px !important;
}
.header{
  background-color: #278ea5;;
}
</style>
