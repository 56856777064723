<template>
  <div>
    <v-container>
      <div v-if="isLoading" class="loader-container">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-container fluid>
          <v-card elevation="5" class="mx-auto" max-width="600">
            <v-card-text>
              <v-col cols="12" class="text-center py-3">
                <h2 class="text-decoration-underline">New Fund Request</h2>
              </v-col>
              <v-col cols="12" class="text-center py-3">
                <h4 class="text-color-primary">
                  Copy below UPI Address and send money
                </h4>
              </v-col>
              <v-col cols="12" class="py-3">
                <h4>
                  UPI ID: {{ bankDetails["upiId"] }}
                  <v-btn
                    class="ml-2"
                    small
                    icon
                    @click="copyToClipboard(bankDetails['upiId'])"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </h4>
              </v-col>
              <v-form ref="form" @submit.prevent="submitForm">
                <v-text-field
                  v-model="txnNumber"
                  label="Transaction Number"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="amount"
                  label="Amount in INR(₹)"
                  required
                ></v-text-field>
                <v-file-input
                  v-model="file"
                  accept="image/*"
                  label="Upload Proof"
                  @change="onFileChange"
                ></v-file-input>
                <p v-if="file === null">No File selected</p>
                <p v-else>{{ file?.name }}</p>
                <v-row justify="center" class="my-10">
                  <v-btn
                    type="submit"
                    class="mt-2"
                    color="#278EA5"
                    :disabled="disableButton"
                  >
                    <p v-if="!disableButton">Submit</p>
                    <p v-else>Submitting...</p>
                  </v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </v-container>
  </div>
</template>
<script>
import { BASE_URL, failureAlert, successAlert } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "NewFundRequest",
  data() {
    return {
      disableButton: false,
      isLoading: false,
      txnNumber: "",
      amount: "",
      memberId: "",
      file: null,
      bankDetails: {},
    };
  },
  async created() {
    // Your code here
    this.memberId = localStorage.getItem("memberId");
    this.isLoading = true;
    await this.getAdminBankDetails();
    this.isLoading = false;
  },
  methods: {
    async getAdminBankDetails() {
      // Your code here
      this.isLoading = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/admin/bank_details/VC99999999`
        );
        if (response.status === 200) {
          console.log(response.data);
          this.bankDetails = response.data;
        }
        console.log(this.bankDetails);
      } catch (e) {
        console.error(e);
        failureAlert("Sorry",e.response.data.message);
      }
      this.isLoading = false;
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Address copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    },
    async submitForm() {
      if (this.file === null) {
        alert("Please upload proof");
        return;
      }
      if (this.amount <= 0) {
        alert("Please enter amount greater than 0");
        return;
      }
      if (this.amount < 10) {
        alert("Amount should be greater than 10");
        return;
      }
      this.disableButton = true;
      const formData = new FormData();
      formData.append("txnNumber", this.txnNumber);
      formData.append("amount", this.amount);
      // formData.append("memberId", this.memberId);
      formData.append("memberId", this.memberId);
      formData.append("image", this.file);
      try {
        const response = await axios.post(
          `${BASE_URL}/fund/new_request`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          successAlert("Money will be sent to your account within 24 hours");
          this.amount = "";
          this.txnNumber = "";
          this.file = null;
        } else {
          alert("Something went wrong please try again");
        }
      } catch (e) {
        console.log(e);
        failureAlert("Sorry",e.response.data.message);
      }
      this.disableButton = false;
    },

    onFileChange(event) {
      this.file = event.target.files[0];
      console.log(event.target.files);
    },
  },
};
</script>

<style scoped>
.top-up-card {
  margin-top: 12px;
  min-width: 500px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
