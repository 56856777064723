<template>
    <div>
     
      <v-row>
      
      <v-table class="my-table">
        <thead>
          <tr>
            <th class="my-table-header" v-for="key in headers" :key="key">{{ key }}</th>
            <th class="my-table-header" v-if="actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          <p v-if="rows.length === 0">No data Found</p>
          <tr v-for="(row, index) in rows" :key="index">
            <td v-for="(value, key) in row" :key="key">
              <div v-if="key === 'orderId' || key==='customerId' || key === 'productId'">
                </div>
              <div v-else-if="key === 'imageUrl'">
                <img v-if="value" :src="value" alt="Image" class="my-table-image">
                <span v-else>No Image</span>
              </div>
              <div v-else-if="(key === 'sNo') || (key === 'SNo')">{{ index + 1 }}</div>
              <div v-else>{{ value }}</div>
            </td>
            <td v-if=" actions && Object.keys(actions).length > 1">
              <button class="accept-button" @click="actions.accept(row.orderId)">Accept</button>
              <button class="reject-button" @click="actions.reject(row.orderId)">Reject</button>
            </td>
            <td v-else-if="actions && Object.keys(actions).length === 1">
              <button class="accept-button" @click="actions.deliver(row.orderId)">Delivered</button>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-row>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      headers: {
        type: Array,
        required: true
      },
      rows: {
        type: Array,
        required: true
      },
      actions: {
        type: Object,
        default: () => null
      }
    }
  }
  </script>
  
  <style scoped>
  .my-table {
    width: 100%;
  }
  
  .my-table-header {
    font-weight: bold;
  }
  
  .my-table-image {
    max-width: 100px;
  }
  
  .accept-button, .reject-button {
    margin: 5px;
  }
  </style>
  