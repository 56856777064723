<template>
  <div>
    <v-main>
      <v-container>
        <div v-if="isLoading" class="loader-container">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-container fluid>
            <v-card elevation="5" class="mx-auto" max-width="600">
              <v-card-text>
                <v-row justify="center" class="my-0 py-0">
                  <v-col cols="12" class="text-center py-3">
                    <h2 class="text-decoration-underline">
                      Wallet to Fund Transfer
                    </h2>
                  </v-col>
                  <v-col cols="12" class="text-center py-3">
                    <h3>
                      Available Wallet: ₹ {{ userDetails["availableWallet"] }}
                    </h3>
                  </v-col>

                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="amount"
                      label="Enter Amount in INR(₹)"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="text-center py-1">
                    <v-text-field
                      v-model="password"
                      label="Transaction Password"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      color="#278EA5"
                      dark
                      @click="transferWalletToFund"
                      :disabled="disableButton"
                    >
                      Transfer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
// import axios from 'axios';
import { mapState, mapActions } from "vuex";
import axios from "axios";
axios.defaults.withCredentials = true;
import { BASE_URL, failureAlert, successAlert } from "@/constants";
export default {
  name: "NewWalletToFundTransfer",

  data() {
    return {
      disableButton: false,
      isLoading: false,
      amount: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
  },
  async created() {
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchUserDetails();
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),

    async transferWalletToFund() {
      try {
        if (this.password !== this.userDetails["securityPin"]) {
          alert("Incorrect Transaction Password");
          return;
        }

        if (
          parseInt(this.amount) > parseInt(this.userDetails["availableWallet"])
        ) {
          alert("Insufficient wallet amount");
          return;
        }
        if (parseInt(this.amount) <= 0) {
          alert("Amount should be greater than 0");
          return;
        }
        this.disableButton = true;
        const body = {
          mId: this.userId, // Replace with the actual user ID
          amount: this.amount,
          pin: this.password,
        };
        const res = await axios.post(
          `${BASE_URL}/fund/transfer/wallet_to_fund`,
          body
        ); // Replace with your actual route

        if (res.status === 200 || res.status === 201) {
          alert("Transfer success");
          this.amount = "";
          this.password = "";
          
          successAlert("Transfer success");
        } else {
          alert("Something went wrong");
        }
        await this.fetchUserDetails();
      } catch (e) {
        console.error(e);
        
        failureAlert("Sorry",e.response.data.message);
      }

      this.disableButton = false;
    },
  },
};
</script>
<style scoped>
.top-up-card {
  margin-top: 12px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
