<template>
  <div v-if="isLoading" class="loader-container">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <div v-else>
    <div class="mb-3">
      <v-col cols="12" class="text-center py-1">
        <h3 class="m-2 my-5 orange">Direct Team</h3>
      </v-col>
      <v-table class="my-table">
        <thead>
          <tr>
            <th class="my-table-header" v-for="key in keys" :key="key">
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody v-if="leftValues.length > 0">
          <tr v-for="(value, index1) in leftValues" :key="index1">
            <td
              class="my-table-cell"
              v-for="(item, index) in value"
              :key="index"
            >
              {{ index === 0 ? index1 + 1 : item }}
              <!-- <span v-if="index === 2"
                ><v-btn
                  flat
                  class="ml-2"
                  small
                  icon
                  @click="copyToClipboard(item)"
                >
                  <v-icon class="flat-icon">mdi-content-copy</v-icon>
                </v-btn></span
              > -->
            </td>
          </tr>
        </tbody>
        <div v-else class="no-data mt-5">No data found</div>
      </v-table>
    </div>
    <!-- <div class="mb-3">
      <v-col cols="12" class="text-center py-1">
        <h3 class="m-2 my-5">Right Team</h3>
      </v-col>
      <v-table class="my-table">
        <thead>
          <tr>
            <th class="my-table-header" v-for="key in keys" :key="key">
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody v-if="rightValues.length > 0">
          <tr v-for="(value, index1) in rightValues" :key="index1">
            <td
              class="my-table-cell"
              v-for="(item, index) in value"
              :key="index"
            >
              {{ index === 0 ? index1 + 1 : item }}
              <span v-if="index === 2"
                ><v-btn
                  flat
                  class="ml-2"
                  small
                  icon
                  @click="copyToClipboard(item)"
                >
                  <v-icon class="flat-icon">mdi-content-copy</v-icon>
                </v-btn></span
              >
            </td>
          </tr>
        </tbody>
        <div v-else class="no-data mt-5">No data found</div>
      </v-table>
    </div> -->
  </div>
</template>

<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
  name: "DirectTeam",
  data() {
    return {
      keys: ["SNo", "Name", "MemberID", "PlacementID", "PhoneNo", "Status"],
      leftValues: [],
      rightValues: [],
      isLoading: false,
      memberId: "",
    };
  },
  async created() {
    this.memberId = localStorage.getItem("memberId");
    if (!this.memberId) {
      this.$router.push("/login");
      return;
    }
    await this.fetchMyTeam();
  },
  methods: {
    async fetchMyTeam() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/user/direct_team/${this.memberId}`
        );
        this.leftValues = response.data.team;
        // this.rightValues = response.data.right;
        console.log(this.leftValues);
        // console.log(this.rightValues);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    },
  },
};
</script>
<style scoped>
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
}
.my-table-header {
  background-color: #278ea5;
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.my-table-cell {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.flat-icon {
  box-shadow: none !important;
}
</style>
