<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <div class="buttons-container">
            <v-btn @click="$router.push('/product_list')" class="header-btn">
              Product List
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="buttons-container">
         <v-btn  @click="redirectToWhatsApp" class="header-btn">
           <span class="mr-2">Join WhatsApp</span>
          <v-icon
            large
            color="green darken-2"
            @click="redirectToWhatsApp"
            style="cursor: pointer"
          >
            mdi-whatsapp
          </v-icon>
         </v-btn>
        </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-container>
        <div v-if="isLoading" class="loader-container">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-container>
            <v-row class="pt-3 justify-center">
              <!-- User Card -->
              <v-col cols="12" md="8" lg="6">
                <v-card class="user-card pt-5 pb-5 custom-card">
                  <v-row class="justify-center">
                    <v-img
                      class="profile-image mb-4"
                      src="../../assets/profile.png"
                      alt="Profile Picture"
                      width="120"
                      height="120"
                    />
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="text-right">
                      <span class="card-label">Username :</span>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <strong>{{ userDetails["userName"] }}</strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="text-right">
                      <span class="card-label">User ID :</span>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <strong>{{ userDetails["memberId"] }}</strong>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="6" class="text-right">
                      <span class="card-label">Activated On :</span>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <strong v-if="userDetails['status'] === 'active'">{{
                        userDetails["activatedDate"]
                      }}</strong>
                      <strong v-else class="text-red">Not Activated</strong>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="6" class="text-right">
                      <span class="card-label">Referral Link :</span>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <strong v-if="!referalLinkCopied">Click To Copy</strong>
                      <strong v-else class="text-green">Copied</strong>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            `https://www.vcanshoppe.com/register?referalID=${userDetails['memberId']}`,
                            'left'
                          )
                        "
                        class="ml-2"
                      >
                        <v-icon v-if="!referalLinkCopied" size="18"
                          >mdi-content-copy</v-icon
                        >
                        <v-icon v-else size="18">mdi-check</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="6" class="text-right">
                      <span class="card-label">Right Referral Link :</span>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <strong v-if="!rightReferalLinkCopied"
                        >Click To Copy</strong
                      >
                      <strong v-else class="text-green">Copied</strong>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            `https://www.vcanshoppe.com/register?referalID=${userDetails['memberId']}`,
                            'right'
                          )
                        "
                        class="ml-2"
                      >
                        <v-icon v-if="!rightReferalLinkCopied"
                          >mdi-content-copy</v-icon
                        >
                        <v-icon v-else>mdi-check</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row> -->
                </v-card>
              </v-col>
              <!-- Cards -->
              <v-row
                justify="center"
                :align="center"
                class="d-flex pt-10"
                style="width: 100%; height: calc(100vh - 200px)"
              >
                <v-col
                  v-for="(card, index) in cards"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  class="d-flex justify-center"
                >
                  <v-card
                    class="cards rounded-lg home-card"
                    :style="{
                      height: '150px',
                      width: '200px',
                      backgroundColor: card.backgroundColor,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }"
                  >
                    <div class="content" style="text-align: center">
                      <v-icon
                        class="icon"
                        :color="'#E0F7FA'"
                        style="font-size: 48px"
                        >{{ card.imageSrc }}</v-icon
                      >
                      <!-- <div class="headline" style="margin-top: 10px">
                        {{ index == 1 ? "Carry Forward" : card.heading }}
                      </div>
                      <div v-if="index == 1">
                        <div v-if="!carryForwardLoading" class="count" style="margin-top: 5px">
                          <span v-if="carryForwardDirection != ''">{{
                            carryForwardDirection
                          }}</span>
                          <span v-else>
                            <v-chip class="responsive-chip bg-orange" text :color="'#FFA500'" @click="getCarryForward">
                              Get Direction
                            </v-chip>
                          </span>
                        </div>
                        <div v-else style="margin-top: 5px">
                          <v-progress-circular indeterminate :color="'#E0F7FA'"></v-progress-circular>
                        </div>
                      </div>
                      <div v-else class="count" style="margin-top: 5px">
                        {{
                          numericCards.includes(index)
                          ? "₹ " +
                          userDetails[Object.keys(userDetails)[index]]
                          : userDetails[Object.keys(userDetails)[index]]
                        }}
                      </div> -->

                      <div class="headline" style="margin-top: 10px">
                        {{ card.heading }}
                      </div>
                      <div class="count" style="margin-top: 5px">
                        {{
                          numericCards.includes(index)
                            ? "₹ " +
                              userDetails[Object.keys(userDetails)[index]]
                            : userDetails[Object.keys(userDetails)[index]]
                        }}
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </div>
      </v-container>
    </v-row>
  </div>

  <!-- <v-container fluid>
    <v-row class="d-none d-sm-flex text-center mt-10" v-if="userDetails['isMerchant']">
      <v-col>
        <p>You are a merchant now</p>
        <v-btn color="primary" @click="$router.push('/buy_requests')">Go to Buy Requests Page</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="showMobileButton && userDetails['isMerchant']" class="d-sm-none fixed-bottom">
      <v-col>
        <v-btn color="primary" @click="$router.push('/buy_requests')" block class="padded-button">Go to Buy Requests
          Page</v-btn>
      </v-col>
    </v-row>
  </v-container> -->
</template>

<!-- <script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
import { mapState, mapActions } from "vuex";
export default {
  
  name: "HomeComponent",
  data() {
    return {
      isLoading: false,
      referalLinkCopied: false,
      rightReferalLinkCopied: false,
      numericCards: [2, 3, 4, 5, 6, 7, 8, 9],
      midCopied: false,
      carryForwardLoading: false,
      carryForwardDirection: "",
      windowWidth: 0,
      cards: [
        {
          heading: "My Direct Team",
          imageSrc: "mdi-account-group",
          backgroundColor: "#4CAF50",
        },
        {
          heading: "Carry Forward",
          imageSrc: "mdi-compass",
          backgroundColor: "#3F51B5",
        },
        {
          heading: "My Total Income",
          imageSrc: "mdi mdi-currency-inr",
          backgroundColor: "#FF5722",
        },
        {
          heading: "Available Wallet",
          imageSrc: "mdi-wallet",
          backgroundColor: "#FFC107",
        },
        {
          heading: "Bank Withdrawals",
          imageSrc: "mdi-bank-transfer-out",
          backgroundColor: "#9C27B0",
        },
        {
          heading: "Available Funds",
          imageSrc: "mdi-wallet",
          backgroundColor: "#00BCD4",
        },
        {
          heading: "Repurchase Income",
          imageSrc: "mdi-gift-outline",
          backgroundColor: "#FF9800",
        },
        {
          heading: "Cashback Wallet",
          imageSrc: "mdi-gift-outline",
          backgroundColor: "#673AB7",
        },
        {
          heading: "Royality",
          imageSrc: "mdi-crown",
          backgroundColor: "#278EA5",
          //#3F51B5
        },
      ],
      memberId: localStorage.getItem("memberId"),
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),dfcfsatrq45e3
    iconSize() {
      if (window.innerWidth <= 600) {
        return 30; // size for small screens
      } else {
        return 61; // size for large screens
      }
    },
  },
  async created() {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    this.memberId = localStorage.getItem("memberId");
    if (!isLoggedIn) {
      this.$router.push({ name: "login" });
      return;
    }
    this.isLoading = true;
    // Fetch user details using Vuex action
    await this.fetchUserDetails(this.memberId);
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    copyToClipboard(text, type) {
      if (type === "left") {
        this.referalLinkCopied = true;
        this.rightReferalLinkCopied = false;
        this.midCopied = false;
      } else if (type === "right") {
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = true;
        this.midCopied = false;
      } else {
        this.midCopied = true;
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = false;
      }
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      setTimeout(() => {
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = false;
        this.midCopied = false;
      }, 1500);
    },
    async getCarryForward() {
      this.carryForwardLoading = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/carry_forward/${this.memberId}`
        );
        console.log(response.data);
        if (response.data.left && response.data.right) {
          this.carryForwardDirection = "NA";
        } else if (response.data.left) this.carryForwardDirection = "Right";
        else this.carryForwardDirection = "Left";
        this.carryForwardLoading = false;
      } catch (error) {
        console.error("Error fetching carry forward data:", error);
      } finally {
        this.carryForwardLoading = false;
      }
    },
  },
};
</script> -->
<script>
import { BASE_URL } from "@/constants";
import axios from "axios";
axios.defaults.withCredentials = true;
import { mapState, mapActions } from "vuex";

export default {
  name: "HomeComponent",
  data() {
    return {
      isLoading: false,
      referalLinkCopied: false,
      rightReferalLinkCopied: false,
      numericCards: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      midCopied: false,
      carryForwardLoading: false,
      carryForwardDirection: "",
      windowWidth: 0,
      cards: [
        // {
        //   heading: "My Direct Team",
        //   imageSrc: "mdi-account-group",
        //   backgroundColor: "#4CAF50",
        // },
        // {
        //   heading: "Carry Forward",
        //   imageSrc: "mdi-compass",
        //   backgroundColor: "#3F51B5",
        // },
        {
          heading: "My Total Income",
          imageSrc: "mdi mdi-currency-inr",
          // backgroundColor: "#FF5722",
          backgroundColor: "#4CAF50",
        },
        {
          heading: "Available Wallet",
          imageSrc: "mdi-wallet",
          // backgroundColor: "#FFC107",
          backgroundColor: "#3F51B5",
        },
        {
          heading: "Bank Withdrawals",
          imageSrc: "mdi-bank-transfer-out",
          backgroundColor: "#9C27B0",
        },
        {
          heading: "Available Funds",
          imageSrc: "mdi-wallet",
          backgroundColor: "#278EA5",
          // backgroundColor: "#00BCD4",
        },
        // {
        //   heading: "Repurchase Income",
        //   imageSrc: "mdi-gift-outline",
        //   backgroundColor: "#FF9800",
        // },
        // {
        //   heading: "Cashback Wallet",
        //   imageSrc: "mdi-gift-outline",
        //   backgroundColor: "#673AB7",
        // },
        // {
        //   heading: "Royality",
        //   imageSrc: "mdi-crown",
        //   backgroundColor: "#278EA5",
        // },
      ],
      memberId: localStorage.getItem("memberId"),
      showMobileButton: false,
    };
  },
  computed: {
    ...mapState(["userDetails", "userId", "userName"]),
    iconSize() {
      return window.innerWidth <= 600 ? 30 : 61;
    },
  },
  async created() {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    this.memberId = localStorage.getItem("memberId");
    if (!isLoggedIn) {
      this.$router.push({ name: "login" });
      return;
    }
    this.isLoading = true;
    await this.fetchUserDetails(this.memberId);
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserDetails"]),
    redirectToWhatsApp() {
      const whatsappUrl = "https://chat.whatsapp.com/EnITX5bJOgp3QNQa7yUOyj";
      window.open(whatsappUrl, "_blank");
    },
    copyToClipboard(text, type) {
      if (type === "left") {
        this.referalLinkCopied = true;
        this.rightReferalLinkCopied = false;
        this.midCopied = false;
      } else if (type === "right") {
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = true;
        this.midCopied = false;
      } else {
        this.midCopied = true;
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = false;
      }
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      setTimeout(() => {
        this.referalLinkCopied = false;
        this.rightReferalLinkCopied = false;
        this.midCopied = false;
      }, 1500);
    },
    async getCarryForward() {
      this.carryForwardLoading = true;
      try {
        const response = await axios.get(
          `${BASE_URL}/fund/carry_forward/${this.memberId}`
        );
        if (response.data.left && response.data.right) {
          this.carryForwardDirection = "NA";
        } else if (response.data.left) this.carryForwardDirection = "Right";
        else this.carryForwardDirection = "Left";
      } catch (error) {
        console.error("Error fetching carry forward data:", error);
      } finally {
        this.carryForwardLoading = false;
      }
    },
    checkScrollPosition() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
      this.showMobileButton = isAtBottom;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScrollPosition);
    this.checkScrollPosition();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.cards {
  width: 100%;
  height: 100%;
}

.home-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #278ea5;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-label {
  font-weight: bold;
  color: white;
}

.responsive-chip {
  font-size: 0.9rem;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

@media only screen and (max-width: 600px) {
  .home-card {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 5px;
  }

  .headline {
    font-size: 1rem;
  }
}

.custom-card {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease, border 0.3s ease;
  border: 1px solid #e0e0e0;
  background-color: #ff990094;
}

.custom-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  border: 2px solid #3f51b5;
}

.profile-image {
  border-radius: 10px;
}

.card-label {
  font-weight: 500;
  color: #278ea5;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.v-btn {
  padding: 0;
  min-width: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.padded-button {
  padding: 12px 24px;
  margin-left: 15px;
}

.header-btn {
  margin: 0 10px;
  /* Space between buttons */
  padding: 10px 20px;
  /* Adjust button padding */
  background-color: #007bff;
  /* Primary button color */
  color: #ffffff;
  /* Text color */
  border: none;
  /* Remove border */
  border-radius: 4px;
  /* Rounded corners */
}

.header-btn:hover {
  background-color: #0056b3;
  /* Darker shade for hover effect */
}

.header-btn:active {
  background-color: #004080;
  /* Even darker shade when button is pressed */
}

.buttons-container {
  display: flex;
  /* Use flexbox to arrange buttons */
  justify-content: center;
  /* Center buttons horizontally */
  gap: 10px;
  /* Space between buttons */
}

.count {
  font-size: 1.2rem;
}
</style>
