<template>
    <div>
        <div class="mt-10">
        <h1 class="text-center mb-5 heading">Product List</h1>
        <div class="text-center mb-5">Available Fund: <b>{{ userDetails.availableFunds }}</b></div>
        <div v-if="fetchingProducts" class="text-center">Fetching Products...</div>
        <div v-else-if="!productList.length" class="text-center">No Products Found</div>
        <ProductList :products="productList" />
    </div>
    </div>
</template>

<script>
import { BASE_URL, failureAlert } from "@/constants";
import ProductList from "./ProductList.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
    name: "ProductStore",
    components: { ProductList },
    data() {
        return {
            fetchingProducts: false,
            productList: [],
        }
    },
    async created() {
        this.memberId = localStorage.getItem("memberId");
        if (!this.memberId) {
            this.$router.push("/login");
            return;
        }
        this.fetchingProducts = true;
        await this.fetchAllProducts();
        this.fetchingProducts = false;
    },
    computed: {
        ...mapState(["userDetails", "userId", "userName"]),
    },
    methods: {
        ...mapActions(["fetchUserDetails"]),
        async fetchAllProducts() {
            // Call API to fetch all products
            try {
                const response = await axios.get(`${BASE_URL}/admin/products_available`);
                this.productList = response.data;
            } catch (error) {
                console.error(error);
                failureAlert("Sorry", "Error while fetching products");
            }
        },
    }
}
</script>