<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col
        v-for="product in products"
        :key="product._id"
        cols="6"
        class="d-flex justify-center mb-4"
      >
        <div class="product-card-container">
          <!-- Product Card -->
          <v-card class="product-card" @click="goToProductDetail(product._id)">
            <v-img
              class="product-img"
              :src="product.imageUrl"
              :alt="product.name"
            />
          </v-card>

          <!-- Product Details -->
          <div class="product-card-details">
            <v-card-title class="product-card-title">{{
              product.name
            }}</v-card-title>
            <div class="product-details">
              <p>{{ product.description }}</p>
              <p><strong>Price:</strong> {{ product.price }}</p>
              <!-- Buy Button -->
              <v-btn @click.stop="goToProductDetail(product._id)" color="primary"
                >Buy</v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASE_URL } from "@/constants";
import axios from "axios";

export default {
  name: "ProductList",
  data() {
    return {
      products: [],
      isLoading: true,
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(`${BASE_URL}/products`);
        this.products = response.data;
        this.isLoading = false;
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
        this.isLoading = false;
      }
    },
    goToProductDetail(productId) {
      console.log(productId);
      this.$router.push({ name: "ProductDetail", params: { id: productId } });
    },
  },
};
</script>

<style scoped>
.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}

.product-card {
  width: 100%;
  padding-top: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  overflow: hidden;
}

.product-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card-details {
  width: 100%;
  text-align: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.9);
  color: black;
}

.product-card-title {
  margin: 0;
  font-size: 1rem;
}

.product-details p {
  margin: 8px 0;
}

.product-card:hover {
  filter: brightness(0.8);
}
</style>
