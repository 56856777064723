import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from "./store"
import Toast from "vue3-toastify";
import 'vue3-toastify/dist/index.css';
import "./styles.css"
loadFonts()

createApp(App).use(router)
  .use(vuetify)
  .use(store)
  .use(Toast, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  })
  .mount('#app')
